import * as React from 'react';

import Rating from '@mui/material/Rating';
import { ReadOnlyRatingProps } from './types';

export const BasicRating = (props: any) => {
  return (
    <Rating
      name={props.name}
      onChange={e => {
        props.handleChange(e);
      }}
      value={parseInt(props.value)}
    />
  );
};

export function ReadOnlyRating(props: ReadOnlyRatingProps) {
  return (
    <Rating
      name="read-only"
      readOnly
      size={props?.size}
      sx={{ fontSize: ['12px', '14px', '16px', '18px'] }}
      value={props.value}
    />
  );
}
