import React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box } from '../Basics';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';

export const BasicDate = props => {
  let {
    bgColor,
    border,
    inputColor,
    display,
    inputFormat,
    labelMargin,
    minDate,
    ...rest
  } = props;
  if (inputFormat) {
    if (window.innerWidth < 768) {
      inputFormat = inputFormat[0];
    } else {
      inputFormat = inputFormat[1];
    }
  }
  return (
    <Box {...rest}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          components={{
            ...(props.variant === 'standard' && {
              OpenPickerIcon: CalendarIcon as React.ElementType<
                React.SVGProps<SVGSVGElement>
              >,
            }),
          }}
          disableMaskedInput={true}
          inputFormat={inputFormat || 'MM/dd/yyyy'}
          label={props.placeholder}
          minDate={minDate && minDate}
          onChange={props.handleChange}
          renderInput={params => (
            <TextField
              {...params}
              sx={{
                border: 'none',
                button: {
                  boxShadow: 'inset 0 0 20px 10px #FFF4F0',
                },
                svg: {
                  color: '#FF8058',
                  zIndex: 10,
                },
                input: {
                  color: inputColor || '',
                  zIndex: 10,
                },
                label: {
                  color: inputColor || '#9DA7BC',
                  fontSize: '12px',
                  marginTop: labelMargin || '0px',
                  display: display || 'initial',
                },
                height: '100%',
                width: '100%',
                '& .MuiInputBase-root': {
                  borderRadius: '8px',
                  ...(props.fullHeight && {
                    height: '100%',
                  }),
                },
                '& .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root':
                  {
                    height: props.height || '45px',
                    backgroundColor: bgColor || '#F5F5F7',
                  },
                '.css-1d3z3hw-MuiOutlinedInput-notchedOutline ': {
                  border: border || '1px solid rgba(0, 0, 0, 0.23)',
                  backgroundColor: bgColor || '#F5F5F7',
                  borderRadius: '8px',
                },
                ...(props.variant === 'standard' && {
                  '& .MuiInputLabel-root, .MuiInputLabel-root.Mui-focused': {
                    color: '#566176',
                    paddingRight: '36px',
                  },
                  '& .MuiIconButton-root': {
                    backgroundColor: '#FFF',
                    boxShadow: 'none',
                    borderRadius: 0,
                    marginRight: 0,
                  },
                  '& .MuiInput-root:after': {
                    borderBottomColor: 'initial',
                  },
                }),
                '& .MuiInputLabel-root': {
                  top: ['-3px'],
                },
              }}
              variant={props.variant}
            />
          )}
          value={props.value}
        />
      </LocalizationProvider>
    </Box>
  );
};
