import { FormValues } from './types';

export const initialValues: FormValues = {
  email: '',
};

export const errorValues = {
  email: {
    invalid: 'Invalid email address',
    required: 'Email is required',
  },
};
