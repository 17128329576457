import React from 'react';
import { Flex, Text, Image, Button } from '../Blocks';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as PlusCircleIcon } from '../../../assets/icons/plus-circle.svg';
import { ReactComponent as DownIcon } from '../../../assets/icons/down.svg';
import { ReactComponent as MenuIcon } from '../../../assets/icons/menu.svg';

import { NavigationProps } from './types';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

export const Navigation = (props: NavigationProps) => {
  const history = useHistory();
  const location = useLocation();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null,
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const pages = [
    {
      route: '/userReservationList',
      component: (
        <Flex width={'100%'}>
          <Text
            color={
              location.pathname === '/userReservationList'
                ? 'secondary.1'
                : 'black.7'
            }
            fontFamily={'Poppins'}
            fontSize={[0]}
            fontWeight={[5]}
            lineHeight={[2]}
            width={'100%'}
          >
            Reserved Events
          </Text>
        </Flex>
      ),
    },
    {
      route: '/search',
      component: (
        <Flex width={'100%'}>
          <Text
            color={location.pathname === '/search' ? 'secondary.1' : 'black.7'}
            fontFamily={'Poppins'}
            fontSize={[0]}
            fontWeight={[5]}
            lineHeight={[2]}
          >
            Feeds
          </Text>
        </Flex>
      ),
    },
    {
      route: '/savedEvents',
      component: (
        <Flex width={'100%'}>
          <Text
            color={
              location.pathname === '/savedEvents' ? 'secondary.1' : 'black.7'
            }
            fontFamily={'Poppins'}
            fontSize={[0]}
            fontWeight={[5]}
            lineHeight={[2]}
          >
            Favorites
          </Text>
        </Flex>
      ),
    },
    {
      route: '/reservationList',
      component: (
        <Flex width={'100%'}>
          <Text
            color={
              location.pathname === '/reservationList'
                ? 'secondary.1'
                : 'black.7'
            }
            fontFamily={'Poppins'}
            fontSize={[0]}
            fontWeight={[5]}
            lineHeight={[2]}
          >
            Event Request
          </Text>
        </Flex>
      ),
    },
    {
      route: '/specialTripList',
      component: (
        <Flex width={'100%'}>
          <Text
            color={
              location.pathname === '/specialTripList'
                ? 'secondary.1'
                : 'black.7'
            }
            fontFamily={'Poppins'}
            fontSize={[0]}
            fontWeight={[5]}
            lineHeight={[2]}
          >
            Special Requests
          </Text>
        </Flex>
      ),
    },
  ];

  const settings = [
    <Flex
      alignItems={'center'}
      borderBottom="0.1px solid black"
      flexDirection={'column'}
      justifyContent="center"
      py={2}
      style={{ cursor: 'default' }}
    >
      <Text
        color={'black.1'}
        fontFamily={'Poppins'}
        fontWeight={[3]}
        lineHeight={[2]}
      >
        {localStorage.getItem('firstName')}
      </Text>

      <Text
        color={'black.5'}
        fontFamily={'Poppins'}
        fontSize={['11px']}
        fontWeight={[3]}
        lineHeight={[2]}
        py={1}
      >
        {localStorage.getItem('email')}
      </Text>
    </Flex>,
    <Flex
      justifyContent={'center'}
      onClick={() => {
        history.push(`/user/${localStorage.getItem('id')}`);
      }}
      py={1}
      width={'100%'}
    >
      <Text
        color={'black.1'}
        fontFamily={'Poppins'}
        fontSize={[1]}
        fontWeight={[3]}
        lineHeight={[2]}
      >
        My Profile
      </Text>
    </Flex>,
    <Flex
      justifyContent={'center'}
      onClick={() => {
        history.push(`/editProfile/${localStorage.getItem('id')}`);
      }}
      py={1}
      width={'100%'}
    >
      <Text
        color={'black.1'}
        fontFamily={'Poppins'}
        fontSize={[1]}
        fontWeight={[3]}
        lineHeight={[2]}
      >
        Edit profile
      </Text>
    </Flex>,
    <Flex
      justifyContent={'center'}
      onClick={handleClickOpen}
      py={1}
      width={'100%'}
    >
      <Text
        color={'black.1'}
        fontFamily={'Poppins'}
        fontSize={[1]}
        fontWeight={[3]}
        lineHeight={[2]}
      >
        Logout
      </Text>
    </Flex>,
  ];

  return (
    <>
      {location.pathname !== '/' &&
      location.pathname !== '/login' &&
      location.pathname !== '/signup' ? (
        <AppBar
          color="transparent"
          position="static"
          style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)' }}
          sx={{ mb: [2], py: [1], boxShadow: [0] }}
        >
          <Toolbar>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                aria-controls="menu-appbar"
                aria-haspopup="true"
                aria-label="account of current user"
                color="inherit"
                onClick={handleOpenNavMenu}
                size="small"
              >
                <MenuIcon height={'25px'} width={'25px'} />
              </IconButton>

              <Menu
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                id="menu-appbar"
                keepMounted
                onClose={handleCloseNavMenu}
                open={Boolean(anchorElNav)}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                {pages.map((page, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      history.push(page.route);
                    }}
                    onMouseUp={handleCloseNavMenu}
                  >
                    {page.component}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <MenuItem
              onClick={() => {
                history.push('/createTrip');
              }}
              sx={{ py: [2], px: [1, 4], borderRadius: [4] }}
            >
              <Flex width={'100%'}>
                <PlusCircleIcon />
                <Text
                  color={
                    location.pathname === '/createTrip'
                      ? 'secondary.1'
                      : 'black.7'
                  }
                  fontFamily={'Poppins'}
                  fontSize={[0]}
                  fontWeight={[5]}
                  lineHeight={[2]}
                  mb={['0px']}
                  ml={[3]}
                >
                  Post an Event
                </Text>
              </Flex>
            </MenuItem>
            <Box
              sx={{
                flexGrow: 1,
                display: {
                  xs: 'none',
                  md: 'flex',
                },
                justifyContent: 'center',
                mr: [3],
              }}
            >
              {pages.map((page, index) => (
                <MenuItem
                  key={index}
                  onClick={() => history.push(page.route)}
                  sx={{
                    py: [2],
                    px: [0, 0, 3, 4],
                    borderRadius: [4],
                    mx: [0, 0, 0, 1],
                  }}
                >
                  {page.component}
                </MenuItem>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Flex
                alignItems={'center'}
                backgroundColor={'white.1'}
                borderRadius={'10px'}
                height={['43px', 10]}
                justifyContent={'space-around'}
                ml={'16px'}
                onClick={handleOpenUserMenu}
                style={{ cursor: 'pointer' }}
                width={[13, 17]}
              >
                <DownIcon height={'15px'} width={'15px'} />
                {localStorage.getItem('profileImage') === 'undefined' ? (
                  <Flex
                    alignItems="center"
                    backgroundColor={'secondary.0'}
                    borderRadius={'50%'}
                    color="#fff"
                    height={['35px', '45px']}
                    justifyContent="center"
                    width={['35px', '45px']}
                  >
                    <Text fontSize={['16px', '20px']}>
                      {localStorage
                        .getItem('firstName')
                        ?.slice(0, 1)
                        .toUpperCase()}
                    </Text>
                  </Flex>
                ) : (
                  <Box height={['35px', '45px']} width={['35px', '45px']}>
                    <Image
                      border="2px solid white"
                      borderRadius={'50%'}
                      height="100%"
                      src={localStorage.getItem('profileImage') as string}
                      width="100%"
                    />
                  </Box>
                )}
              </Flex>

              <Menu
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                id="menu-appbar"
                keepMounted
                onClose={handleCloseUserMenu}
                open={Boolean(anchorElUser)}
                sx={{
                  mt: '60px',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {settings.map((setting, index) => (
                  <MenuItem key={index} onClick={handleCloseUserMenu}>
                    {setting}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
      ) : null}
      <Box>
        <Dialog
          aria-describedby="alert-dialog-description"
          aria-labelledby="alert-dialog-title"
          onClose={handleClose}
          open={open}
        >
          <DialogTitle id="alert-dialog-title">
            <Text
              color={'black.7'}
              fontFamily={'Poppins'}
              fontSize={[1, 2]}
              fontWeight={[3]}
              lineHeight={[2]}
            >
              Are you sure you want to Sign out?
            </Text>
          </DialogTitle>

          <DialogActions>
            <Button color="red" fontSize={[1]} onClick={handleClose} p={[2]}>
              Cancel
            </Button>
            <Button
              autoFocus
              color="primary.0"
              fontSize={[1]}
              onClick={props.onLogoutClick}
              p={[2]}
            >
              Sign out
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};
