import { ErrorMessage, Form, Formik } from 'formik';
import React from 'react';
import { Box, Button, Flex, Input, Text } from '../Blocks';
import { ResetPasswordComponentProp } from './types';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import { ReactComponent as EyeSlashIcon } from 'assets/icons/eye-slash.svg';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';

const ResetPasswordPage = (props: ResetPasswordComponentProp) => {
  return (
    <Flex alignItems={'center'} justifyContent={'center'} m={3}>
      <Flex
        alignItems={'center'}
        bg={'white.0'}
        borderRadius={2}
        boxShadow={1}
        flexDirection={'column'}
        maxWidth={['370px', '550px']}
        mb={7}
        mt={5}
        pb={7}
        pt={5}
        px={5}
        width={['100%', '65%', '55%']}
      >
        <Text as={'h2'} color={'black.7'} fontSize={3} mb={5}>
          Reset Password
        </Text>
        <Formik
          initialValues={props.initialValues}
          onSubmit={props.onSubmit}
          validationSchema={props.validationSchema}
        >
          <Form style={{ width: '100%' }}>
            <Flex alignItems={'center'} flexDirection={'column'}>
              <Box mb={4} width={['90%', '70%', '55%', '65%']}>
                <Text
                  as={'label'}
                  color={'black.7'}
                  fontSize={1}
                  width={'100%'}
                >
                  Enter your new password
                  <Input
                    borderColor={'black.3'}
                    borderRadius={2}
                    borderWidth={'1.5px'}
                    disabled={!props.isValidUrl}
                    icon={props.showPassword ? EyeIcon : EyeSlashIcon}
                    iconOpacity={'0.5'}
                    icon_height={'20px'}
                    icon_right={'8%'}
                    icon_top={'38%'}
                    icon_width={'20px'}
                    mt={2}
                    name="password"
                    onIconClick={props.handleShowPassword}
                    p={3}
                    type={props.showPassword ? 'text' : 'password'}
                    width={'100%'}
                  />
                </Text>
                <Text variant="error">
                  <ErrorMessage name="password" />
                </Text>
              </Box>
              <Box width={['90%', '70%', '55%', '65%']}>
                <Text
                  as={'label'}
                  color={'black.7'}
                  fontSize={1}
                  width={'100%'}
                >
                  Confirm your new password
                  <Input
                    borderColor={'black.3'}
                    borderRadius={2}
                    borderWidth={'1.5px'}
                    disabled={!props.isValidUrl}
                    icon={props.showPassword ? EyeIcon : EyeSlashIcon}
                    iconOpacity={'0.5'}
                    icon_height={'20px'}
                    icon_right={'8%'}
                    icon_top={'38%'}
                    icon_width={'20px'}
                    mt={2}
                    name="confirmPassword"
                    onIconClick={props.handleShowPassword}
                    p={3}
                    type={props.showPassword ? 'text' : 'password'}
                    width={'100%'}
                  />
                </Text>
                <Text variant="error">
                  <ErrorMessage name="confirmPassword" />
                </Text>
              </Box>
              <Flex
                flexDirection={'column'}
                justifyContent={'center'}
                mt={4}
                width={['90%', '70%', '55%', '65%']}
              >
                {props.isPasswordReset ? (
                  <Text
                    as={'p'}
                    color="success"
                    fontFamily="Mulish"
                    fontSize={[1]}
                    fontWeight={[6]}
                    textAlign="center"
                  >
                    Your password has been reset successfully. You'll be
                    redirected to login shortly.
                  </Text>
                ) : (
                  props.error && (
                    <Text
                      as={'p'}
                      fontFamily="Mulish"
                      fontSize={[1]}
                      fontWeight={[6]}
                      textAlign="center"
                      variant="error"
                    >
                      {props.error}
                    </Text>
                  )
                )}
                {!props.isValidUrl && (
                  <Text
                    as={'p'}
                    fontFamily="Mulish"
                    fontSize={[1]}
                    fontWeight={[6]}
                    textAlign="center"
                    variant="error"
                  >
                    This url is invalid. Navigate to&nbsp;
                    <Link to={'/login'}>login page</Link> and click on forgot
                    password to request for account recovery email.
                  </Text>
                )}
                <Button
                  bg={'primary.2'}
                  color={'white.0'}
                  disabled={props.isResettingPassword || !props.isValidUrl}
                  fontSize={1}
                  fontWeight={5}
                  px={3}
                  py={3}
                  style={{
                    cursor: props.isResettingPassword
                      ? 'not-allowed'
                      : 'pointer',
                  }}
                  type="submit"
                  variant="primary"
                  width={'100%'}
                >
                  {props.isResettingPassword ? (
                    <CircularProgress size={20} sx={{ color: 'white' }} />
                  ) : null}
                  &nbsp; Submit
                </Button>
              </Flex>
            </Flex>
          </Form>
        </Formik>
      </Flex>
    </Flex>
  );
};

export default ResetPasswordPage;
