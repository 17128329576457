import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import ActivateAccountComponent from 'app/components/ActivateAccountPage';
import { useDispatch, useSelector } from 'react-redux';
import { useActivateAccountPageSlice } from './slice';
import {
  selectIsAccountActivated,
  selectIsActivatingAccount,
} from './slice/selector';
import { useHistory } from 'react-router-dom';

export function ActivateAccountPage() {
  const params = new URLSearchParams(window.location.search);
  const history = useHistory();
  const dispatch = useDispatch();
  const { actions } = useActivateAccountPageSlice();
  const isActivatingAccount = useSelector(selectIsActivatingAccount);
  const isAccountActivated = useSelector(selectIsAccountActivated);

  useEffect(() => {
    dispatch(
      actions.activateAccount({
        token: params.get('token'),
        email: params.get('email'),
      }),
    );
  }, []);

  useEffect(() => {
    if (isAccountActivated) setTimeout(() => history.push('/'), 2000);
  }, [isAccountActivated]);

  return (
    <>
      <Helmet title="Activate account" />
      <ActivateAccountComponent
        isAccountActivated={isAccountActivated}
        isActivatingAccount={isActivatingAccount}
      />
    </>
  );
}
