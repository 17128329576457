import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';

export const SwitchButton = ({
  handleChange,
  label,
  name,
  value,
  ...props
}) => {
  return (
    <FormControlLabel
      checked={value}
      control={
        <IOSSwitch
          name={name}
          onChange={handleChange}
          value={value}
          {...props}
          sx={{
            '& .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked':
              {
                color: '#FF8058',
              },
            '& .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track':
              {
                backgroundColor: '#ff805830',
              },
          }}
        />
      }
      label={label}
      labelPlacement="top"
      name={name}
      sx={{
        svg: { color: '#FF8058' },
        fontSize: '12px',
        '& .MuiTypography-root': {
          color: props.labelColor,
          fontFamily: props.labelFontFamily,
          fontSize: props.labelFontSize,
          fontWeight: 600,
        },
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        margin: 0,
      }}
    />
  );
};

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch disableRipple focusVisibleClassName=".Mui-focusVisible" {...props} />
))();
