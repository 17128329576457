import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.activateAccountPage || initialState;

export const selectActivateAccountPage = createSelector(
  [selectSlice],
  state => state,
);

export const selectIsActivatingAccount = createSelector(
  [selectSlice],
  state => state.isActivatingAccount,
);

export const selectIsAccountActivated = createSelector(
  [selectSlice],
  state => state.isAccountActivated,
);
