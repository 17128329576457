import * as React from 'react';
import styled from 'styled-components';
import {
  compose,
  space,
  color,
  typography,
  layout,
  border,
  shadow,
} from 'styled-system';
import { CheckboxProps } from './types';

export const CheckboxContainer = styled.label<CheckboxProps>`
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  ${compose(space, color, typography, layout, border, shadow)}
`;

export const CheckboxBox = styled.div<CheckboxProps>`
  width: 1em;
  height: 1em;
  border: 2px solid #ccc;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  flex-shrinks: 0;
  
  &:after{
      content: '\\2714\';
      color: #fff;
        
  }
`;

export const CheckboxInput = styled.input.attrs({
  type: 'checkbox',
})<CheckboxProps>`
  display: none;
  &:checked + ${CheckboxBox} {
    background-color: #2266dc;
    border-color: #2266dc;
  }
`;

export const Checkbox = (props: CheckboxProps) => {
  return (
    <CheckboxContainer {...props}>
      <CheckboxInput />
      <CheckboxBox />
      {props.children}
    </CheckboxContainer>
  );
};
