import DOMPurify from 'dompurify';

//Conver price to string format eg 3300==>3.3K
export function priceLabeler(price: number) {
  const thousand = 1000;
  if (price < thousand) {
    return price;
  }
  let priceLabel = '';
  let count: number = 0;
  let decimalCount: string | number = 0;

  //Billion
  if (price >= Math.pow(thousand, 3)) {
    priceLabel = 'B';
    while (price >= Math.pow(thousand, 3)) {
      price -= Math.pow(thousand, 3);
      ++count;
    }
    decimalCount = ((price % Math.pow(thousand, 3)) / Math.pow(thousand, 2))
      .toString()
      .split('.')[0]
      .charAt(0);
    if (decimalCount.length > 0) {
      return `${count}.${decimalCount}${priceLabel}`;
    }
    return count + priceLabel;
  }
  //Million
  if (price >= Math.pow(thousand, 2)) {
    priceLabel = 'M';
    while (price >= Math.pow(thousand, 2)) {
      price -= Math.pow(thousand, 2);
      ++count;
    }
    decimalCount = ((price % Math.pow(thousand, 2)) / thousand)
      .toString()
      .split('.')[0]
      .charAt(0);
    if (decimalCount.length > 0) {
      return `${count}.${decimalCount}${priceLabel}`;
    }
    return count + priceLabel;
  } else if (price >= thousand) {
    //thousand
    priceLabel = 'K';
    while (price >= thousand) {
      price -= thousand;
      ++count;
    }
    decimalCount = ((price % thousand) / 100)
      .toString()
      .split('.')[0]
      .charAt(0);
    if (decimalCount.length > 0) {
      return `${count}.${decimalCount}${priceLabel}`;
    }
    return count + priceLabel;
  }
}

//capitalize Text
export const capitalizeFn = (text: string) => {
  return text.charAt(0).toUpperCase() + text.substring(1);
};

// Taken from https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#mobile_device_detection
export function checkIsMobileDevice() {
  let hasTouchScreen = false;
  if ('maxTouchPoints' in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  } else {
    var mQ = window.matchMedia('') && matchMedia('(pointer:coarse)');
    if (mQ && mQ.media === '(pointer:coarse)') {
      hasTouchScreen = !!mQ.matches;
    } else if ('orientation' in window) {
      hasTouchScreen = true; // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      var UA = navigator.userAgent;
      hasTouchScreen =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    }
  }

  return hasTouchScreen;
}

export function removeEmptyKeys(obj: object) {
  const cleanObj = Object.keys(obj)
    .filter(key => obj[key] !== null && obj[key] !== undefined)
    .reduce((prev, current) => ({ ...prev, [current]: obj[current] }), {});

  return cleanObj;
}

// To remove HTML tags & get inner Text only
export function convertToText(htmlText: string) {
  const divHTML = document.createElement('div');
  divHTML.innerHTML = DOMPurify.sanitize(htmlText);

  return divHTML.innerText;
}
