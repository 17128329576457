import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { SkeletonProps } from './types';
import {
  border,
  borderRadius,
  boxShadow,
  color,
  compose,
  fontSize,
  fontWeight,
  layout,
  opacity,
  position,
  space,
  textAlign,
} from 'styled-system';

const loading = keyframes`
  from {
    left: -200px;
  }
  to{
    left:100%;
  }
`;

const StyledSkeleton = styled.div<SkeletonProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;

  ::before {
    content: '';
    display: block;
    position: absolute;
    left: -200px;
    top: 0;
    height: 100%;
    width: 200px;
    background: linear-gradient(to right, #f5f8f9, #f5f8f6);
    animation: ${loading} 1000ms ease-in-out infinite;
  }
  ${compose(
    space,
    layout,
    fontSize,
    fontWeight,
    borderRadius,
    color,
    textAlign,
    fontWeight,
    boxShadow,
    position,
    border,
    opacity,
  )}
`;

export const Skeleton = ({
  height,
  number = 0,
  width,
}: SkeletonProps): JSX.Element[] => {
  return [...Array(number)].map(() => {
    return <StyledSkeleton height={height} width={width} />;
  });
};
