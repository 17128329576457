import styled from 'styled-components';
import {
  compose,
  space,
  color,
  lineHeight,
  fontFamily,
  fontSize,
  fontWeight,
} from 'styled-system';
import { theme } from 'styles/theme';
import { Box } from '../Basics';

//card container
export const CardContainer = styled(Box)`
  width: '100%';
  min-height: 402px;
  overflow: hidden;
  box-shadow: ${theme.shadows[1]};
  border-radius: ${theme.radii[2]};
  cursor: pointer;
  &:hover {
    transition: 0.2s ease;
    transform: scale(1.02);
    box-shadow: ${theme.shadows[2]};
  }
  ${compose(color, space, lineHeight, fontFamily, fontSize, fontWeight)};
`;
