import React, { useEffect } from 'react';
import { Footer, Header, Navigation } from 'app/components/DefaultLayout';
import { useDispatch } from 'react-redux';
import { useDefaultLayoutSlice } from './slice';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  selectIsLogedin,
  selectFooterSectionOne,
  selectFooterSectionTwo,
} from './slice/selectors';
import { Box } from 'app/components/Blocks';

export function DefaultLayout(props) {
  const { actions } = useDefaultLayoutSlice();
  const dispatch = useDispatch();
  const history = useHistory();
  const isLogedin = useSelector(selectIsLogedin);
  const footerSectionOne = useSelector(selectFooterSectionOne);
  const footerSectionTwo = useSelector(selectFooterSectionTwo);

  function onLoginClick() {
    history.push('/login');
  }

  function onLogoutClick() {
    dispatch(actions.logout());
    history.push('/');
    window.location.reload();
  }

  // helper functions
  function getCookie(cookiename) {
    var cookiestring = RegExp(cookiename + '=[^;]+').exec(document.cookie);
    return decodeURIComponent(
      !!cookiestring ? cookiestring.toString().replace(/^[^=]+./, '') : '',
    );
  }

  function createCookie(name, value, days) {
    let expires = '';
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    } else expires = '';
    document.cookie = name + '=' + value + expires + '; path=/';
  }

  function eraseCookie(name) {
    createCookie(name, '', -1);
  }

  useEffect(() => {
    dispatch(actions.fetchLandingPageSettingRequest());
    if (!localStorage.getItem('token') && getCookie('x-auth-token')) {
      localStorage.setItem('token', getCookie('x-auth-token'));
      eraseCookie('x-auth-token');
      dispatch(actions.loginWithToken());
    }
  }, []);

  return (
    <>
      <Header
        isLogedin={isLogedin}
        onLoginClick={onLoginClick}
        onLogoutClick={onLogoutClick}
      />
      {localStorage.getItem('token') || isLogedin ? (
        <Navigation onLogoutClick={onLogoutClick} />
      ) : null}
      <Box maxWidth={'1536px'} minHeight="50vh" mx={'auto'} my={'0'}>
        {props.children}
      </Box>
      <Footer
        footerSectionOne={footerSectionOne}
        footerSectionTwo={footerSectionTwo}
      />
    </>
  );
}
