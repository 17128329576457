import React from 'react';
import { theme } from 'styles/theme';
import { Flex } from '../../Blocks';
import { InputFileProps } from './types';
import { InputProps } from './types';

import styled from 'styled-components';
import {
  compose,
  border,
  borderRadius,
  color,
  fontSize,
  fontWeight,
  layout,
  space,
  lineHeight,
  fontFamily,
  boxShadow,
} from 'styled-system';

const StyledFileInput = styled.input<InputProps>`
  ::-webkit-file-upload-button {
    color: ${theme.colors.white[5]};
    background-color: ${theme.colors.white[5]};
    border: 1.5px solid ${theme.colors.black[3]};
    border-radius: ${theme.radii[2]};
    font-size: ${theme.fontSizes[1]};
    font-height: ${theme.lineHeights[2]};
    padding: ${theme.space[3]};
    margin-left: -12px;
    cursor: pointer;
  }
  display: none;

  ${compose(
    border,
    borderRadius,
    color,
    fontSize,
    fontWeight,
    layout,
    space,
    lineHeight,
    fontFamily,
    boxShadow,
  )};
`;

export const InputFile = (props: InputFileProps) => {
  return (
    <Flex
      backgroundColor={'white.1'}
      borderRadius={'5px'}
      height={props.file_height}
      position="relative"
      style={{ cursor: 'pointer' }}
    >
      <StyledFileInput {...props} type="file" />
      {props.icon && (
        <props.icon
          fill={theme.colors.black[2]}
          style={{
            position: 'absolute',
            left: props.icon_left,
            top: props.icon_top,
            height: props.icon_height,
            width: props.icon_width,
          }}
        />
      )}
    </Flex>
  );
};
