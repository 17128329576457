import React from 'react';
import { theme } from 'styles/theme';
import { Flex } from '../Basics';
import { StyledInput } from './Input.styled';
import { InputProps } from './types';

export const Input = (props: InputProps) => {
  const { haveLeftLine, onIconClick, ...rest } = props;
  return (
    <Flex height={props.height} position="relative" width={props.width}>
      {haveLeftLine && (
        <label
          style={{
            position: 'relative',
            borderLeft: '1.5px solid #C7C7C7',
            height: '55%',
            margin: 'auto 0px',
            left: '12px',
          }}
        ></label>
      )}
      <StyledInput {...rest} width="100%" />
      {props.icon && (
        <props.icon
          fill={theme.colors.black[2]}
          onClick={onIconClick}
          style={{
            position: 'absolute',
            right: props.icon_right,
            top: props.icon_top,
            cursor: 'pointer',
            height: props.icon_height,
            width: props.icon_width,
            opacity: props.iconOpacity,
          }}
        />
      )}
    </Flex>
  );
};
