import { PayloadAction } from '@reduxjs/toolkit';
import { IPageState } from 'app/components/SpecialTripListPage/types';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { SpecialTripListPageState, ConfirmST } from '../types';
import { specialTripListPageSaga } from './saga';
import { ISpecialEventInfo } from './types';

export const initialState: SpecialTripListPageState = {
  isLoadingEvents: false,
  error: false,
  specialEventsInfo: {
    eventsReserved: [],
    total: 0,
  },
  targetId: '',
  isConfirmError: false,
  message: '',
  pageState: {
    page: 1,
    size: 10,
  },
};

const slice = createSlice({
  name: 'specialTripListPage',
  initialState,
  reducers: {
    getSpecialTripList: (state, action: PayloadAction<IPageState>) => {
      state.isLoadingEvents = true;
      state.pageState = action.payload;
    },
    getSpecialTripListSuccess: (
      state,
      action: PayloadAction<ISpecialEventInfo>,
    ) => {
      state.isLoadingEvents = false;
      state.specialEventsInfo = action.payload;
    },
    getSpecialTripListError: state => {
      state.error = true;
      state.isLoadingEvents = true;
    },
    confirmSpecialTrip: (state, action: PayloadAction<ConfirmST>) => {
      const { targetId } = action.payload;
      state.isLoadingEvents = true;
      state.isConfirmError = false;
      state.targetId = targetId;
    },
    handleSpecialTripConfirm: (state, action: PayloadAction<string>) => {
      state.isLoadingEvents = true;
      state.message = action.payload;
      state.isConfirmError = false;
    },
    handleSpecialTripConfirmSuccess: (state, action: PayloadAction<string>) => {
      state.isLoadingEvents = false;
      state.message = action.payload;
      state.isConfirmError = false;
    },
    handleSpecialTripConfirmError: (state, action: PayloadAction<string>) => {
      state.isLoadingEvents = false;
      state.message = action.payload;
      state.isConfirmError = true;
    },
  },
});

export const { actions: specialTripListActions } = slice;

export const useSpecialEventPageSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: specialTripListPageSaga });
  return { action: slice.actions };
};
