import { createTheme } from '@mui/material/styles';
import { theme } from './theme';

export const materialTheme = createTheme({
  typography: {
    fontFamily: theme.fonts['Poppins'],
    fontSize: 12,
  },
  palette: {
    text: { primary: theme.colors.black[7] },
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 54,
          height: 26,
          padding: 0,
          overflow: 'visible',
        },
        switchBase: {
          padding: 0,
          margin: 0,
          transitionDuration: '300ms',
          '&.Mui-checked': {
            transform: 'translateX(28px)',
            color: theme.colors.secondary[0],
            '& + .MuiSwitch-track': {
              backgroundColor: theme.colors.secondary[5],
              opacity: 1,
              border: `1px solid ${theme.colors.secondary[0]}`,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: theme.colors.secondary[5],
            border: '6px solid #fff',
          },
        },
        thumb: {
          boxSizing: 'border-box',
          width: 26,
          height: 26,
        },
        track: {
          borderRadius: 26 / 2,
          backgroundColor: '#E9E9EA',
          height: 26,
          opacity: 1,
          transition: 'background-color 500',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
              borderColor: theme.colors.secondary[5],
            },
        },
        input: {
          color: `${theme.colors.black[7]} !important`,
          fontFamily: theme.fonts['Poppins'],
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.MuiInputLabel-root.Mui-focused': {
            color: theme.colors.black[7],
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:after': {
            borderBottom: `2px solid ${theme.colors.black[7]}`,
          },
          '&.MuiSelect-select.MuiInputBase-input.MuiInput-input:focus': {
            backgroundColor: 'none',
          },
        },
      },
    },
  },
});
