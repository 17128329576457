import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import SpecialTripListPageComponent from 'app/components/SpecialTripListPage';
import { useDispatch, useSelector } from 'react-redux';
import { specialTripListActions, useSpecialEventPageSlice } from './slice';
import { useHistory } from 'react-router-dom';
import { selectSpecialEventsInfo } from './slice/selector';
import { selectSpecialTripListISLoading } from 'app/pages/SpecialTripListPage/slice/selector';
import { ISpecialEventData, ISpecialEvent } from './slice/types';

export const SpecialTripListPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  //selectors
  const specialEventsInfo = useSelector(selectSpecialEventsInfo);
  const isLoading = useSelector(selectSpecialTripListISLoading);
  const { action: RPageActions } = useSpecialEventPageSlice();
  const { eventsReserved, total } = specialEventsInfo;

  //states
  const [pageState, setPageState] = useState({
    page: 1,
    size: 10,
  });
  const [open, setOpen] = useState(false);
  const [innerModalOpen, setInnerModalOpen] = useState(false);
  // const [showAlert, setShowAlert] = useState<IAlert>({
  //   open: false,
  // });
  const [confirmType, setConfirmType] = useState('');
  const [showCreateEvent, setShowCreateEvent] = useState(false);
  const [eventReservedData, setEventRData] = useState<ISpecialEventData[]>(
    () => [...eventsReserved],
  );
  const [targetPageData, setTargetPageData] = useState<ISpecialEventData>();
  const [specialDetailData, setSpecialDetailData] = useState<ISpecialEvent>();

  //handlers
  const handleRouteTo = (pageName: string) => {
    history.push(pageName);
  };

  const handleDetailClick = (id: string) => {
    const specialDetail = targetPageData?.data.find(item => item._id === id);
    setSpecialDetailData(specialDetail);
  };

  const handlePageChange = useCallback(
    (event, newPageNumber: number) => {
      let existData = eventReservedData.find(el => el.page === newPageNumber);
      if (existData) {
        setTargetPageData(existData);
        return;
      }
      dispatch(
        RPageActions.getSpecialTripList({
          page: newPageNumber,
          size: 10,
        }),
      );

      setEventRData(prevData => [...prevData, ...eventsReserved]);
      const newData = eventReservedData.find(el => el.page === newPageNumber);
      setTargetPageData(newData);
      setPageState({
        ...pageState,
        page: newPageNumber,
      });
    },
    [eventsReserved, dispatch, RPageActions],
  );

  const findCount = (size: number, total: number) => {
    return Math.ceil(total / size);
  };

  //Modals
  const handleClose = () => setOpen(false);
  const handleOuterModalClose = () => setOpen(false);
  const handleInnerModalClose = () => setInnerModalOpen(false);
  const handleInnerModalOpen = () => {
    setInnerModalOpen(true);
  };
  const handleCancelAction = () => {
    setConfirmType('');
    handleInnerModalClose();
  };

  //accept
  const confirmApprove = targetId => {
    setConfirmType('Accept');
    dispatch(
      specialTripListActions.confirmSpecialTrip({
        targetId,
        confirmType: 'Accept',
        payload: {
          page: pageState.page,
          size: 10,
        },
      }),
    );
  };

  //decline
  const confirmDecline = targetId => {
    setConfirmType('Decline');
    dispatch(
      specialTripListActions.confirmSpecialTrip({
        targetId,
        confirmType: 'Decline',
        payload: {
          page: pageState.page,
          size: 10,
        },
      }),
    );
  };

  const handleAfterConfirm = () => {
    dispatch(specialTripListActions.getSpecialTripList(pageState));
    handleInnerModalClose();
    handleOuterModalClose();
  };

  //effect
  useEffect(() => {
    dispatch(
      specialTripListActions.getSpecialTripList({
        page: 1,
        size: 10,
      }),
    );
  }, []);

  useEffect(() => {
    let timeOut;
    if (!isLoading) {
      timeOut = setTimeout(() => {
        setShowCreateEvent(true);
      }, 300);
    }
    return () => {
      clearTimeout(timeOut);
    };
  }, [isLoading]);

  //memoized
  useMemo(() => {
    setEventRData(prevD => [...prevD, ...eventsReserved]);
    setTargetPageData(eventsReserved[0]);
  }, [eventsReserved]);

  return (
    <>
      <Helmet title="Special Trip Requests" />
      <SpecialTripListPageComponent
        confirmApprove={confirmApprove}
        confirmDecline={confirmDecline}
        confirmType={confirmType}
        count={findCount(pageState.size, total)}
        handleAfterConfirm={handleAfterConfirm}
        handleCancelAction={handleCancelAction}
        handleClose={handleClose}
        handleDetailClick={handleDetailClick}
        handleInnerModalClose={handleInnerModalClose}
        handleInnerModalOpen={handleInnerModalOpen}
        handleOuterModalClose={handleOuterModalClose}
        handlePageChange={handlePageChange}
        handleRouteTo={handleRouteTo}
        innerModalOpen={innerModalOpen}
        isLoading={isLoading}
        open={open}
        setConfirmType={setConfirmType}
        setOpen={setOpen}
        showCreateEvent={showCreateEvent}
        specialDetailData={specialDetailData}
        targetPageData={targetPageData}
      />
    </>
  );
};
