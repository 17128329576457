import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.defaultLayout || initialState;

export const selectLoginPage = createSelector([selectSlice], state => state);

export const selectIsLogging = createSelector(
  [selectSlice],
  state => state.isLogging,
);

export const selectErrorMessage = createSelector(
  [selectSlice],
  state => state.errorMessage,
);

export const selectIsLogedin = createSelector(
  [selectSlice],
  state => state.isLoggedIn,
);

export const selectIsLoadingLandingPageSetting = createSelector(
  [selectSlice],
  state => state.isLoadingLandingPageSetting,
);

export const selectFeaturedOrganizers = createSelector(
  [selectSlice],
  state => state.landingPageSetting?.featuredOrganizers,
);

export const selectTopDestinations = createSelector(
  [selectSlice],
  state => state.landingPageSetting?.topDestinations,
);

export const selectFeaturedSection = createSelector(
  [selectSlice],
  state => state.landingPageSetting?.featuredSection,
);
export const selectFooterSectionOne = createSelector(
  [selectSlice],
  state => state.landingPageSetting?.footerSectionOne,
);

export const selectFooterSectionTwo = createSelector(
  [selectSlice],
  state => state.landingPageSetting?.footerSectionTwo,
);
