import React, { useState } from 'react';
import { ReactComponent as RightIcon } from 'assets/icons/rightIcon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteIcon.svg';
import { ReactComponent as EditIcon } from 'assets/icons/editIcon.svg';
import { ReactComponent as TicketIcon } from 'assets/icons/ticket.svg';
import { ReactComponent as BehiwotLogo } from 'assets/icons/BehiwotLogo.svg';

import { Box, Flex, Text, Image, Grad, Modal, Button } from '../../Blocks';
import { H3 } from '../..//Blocks/Typography';
import { IUserReservationProps } from 'app/pages/UserReservationListPage/types';
import { useHistory } from 'react-router-dom';
import { convertToText, priceLabeler } from 'utils/helpers';
import { ReservationCardSkeleton } from 'app/components/Blocks/Skeleton/Cards/ReservationCard';
import { QRCodeCanvas } from 'qrcode.react';
import html2pdf from 'html2pdf.js';

export default function ReservationCard(props: IUserReservationProps) {
  const { actions, reservation } = props;
  const { handleOpenDialog, isDeleting, setTargetReservation } = actions;
  const history = useHistory();
  const departureDate = new Date(reservation?.departureDate).toDateString();
  const [openModal, setOpenModal] = useState(false);

  const clickModal = () => setOpenModal(!openModal);

  const qrData = {
    reservationId: reservation?._id,
    ticketId: reservation?.ticketId,
  };

  const handlePrint = () => {
    const element = document.getElementById('to-print');
    const opt = {
      margin: [0.5, 2.8, 2.8, 2.8],
      filename: 'ticket.pdf',
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };
    html2pdf().from(element).set(opt).save();
  };

  return (
    <>
      {isDeleting ? (
        <ReservationCardSkeleton />
      ) : (
        <>
          <Modal
            borderRadius="5px"
            onClose={clickModal}
            opacity={1}
            open={openModal}
          >
            <Box height={['50%']} width={[300]}>
              <Flex
                alignContent={['center']}
                backgroundColor={['rgba(200, 200, 200, 0.7)']}
                flexDirection={['column']}
                flexWrap="wrap"
                height="100%"
                justifyContent={'center'}
                pb={'10px'}
                style={{
                  gap: 10,
                }}
                width={'auto'}
              >
                <>
                  <div
                    className="contenido"
                    id="to-print"
                    style={{
                      margin: '20px auto',
                      maxHeight: '600px',
                      maxWidth: '245px',
                      overflow: 'hidden',
                      boxShadow: '1px 1px 10px rgb(102, 102, 104)',
                      backgroundColor: '#fff',
                      borderRadius: '2px',
                      border: '1px solid black',
                    }}
                  >
                    <div className="ticket">
                      <div
                        className="logo"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          width: '100%',
                          paddingTop: '10px',
                          backgroundColor: '#fff',
                        }}
                      >
                        <BehiwotLogo height={'15px'}></BehiwotLogo>
                      </div>
                      <div
                        className="hqr"
                        style={{
                          display: 'table',
                          width: '100%',
                          tableLayout: 'fixed',
                          margin: '0px auto',
                        }}
                      >
                        <div
                          style={{
                            display: 'table-cell',
                            padding: '15px 0px',
                            backgroundRepeat: 'no-repeat',
                            backgroundImage:
                              'radial-gradient(circle at 0 93% , rgba(0,0,0,0.1) .45em, black .6em, white .4em)',
                          }}
                        ></div>
                        <div
                          className="column center"
                          style={{
                            display: 'table-cell',
                            padding: '15px 0px',
                            background: 'white',
                          }}
                        >
                          <QRCodeCanvas
                            style={{
                              marginLeft: '-10px',
                              width: '100px',
                              height: '100px',
                            }}
                            value={JSON.stringify(qrData)}
                          />
                        </div>
                        <div
                          style={{
                            display: 'table-cell',
                            padding: '25px 0px',
                            backgroundRepeat: 'no-repeat',
                            backgroundImage:
                              'radial-gradient(circle at 100% 93% , rgba(0,0,0,0.1) .45em, black .6em, white .2em)',
                          }}
                        ></div>
                      </div>
                      <div
                        style={{
                          padding: '26px',
                          background: '#fff',
                          borderTop: '1px dashed #c3c3c3',
                        }}
                      >
                        <div
                          style={{
                            fontSize: '0.7em',
                            fontWeight: '300',
                            color: 'rgba(0, 0, 0, 0.5)',
                            fontFamily: 'sans-serif',
                            textTransform: 'uppercase',
                            letterSpacing: '1px',
                            margin: '0',
                          }}
                        >
                          Attendee
                        </div>
                        <div
                          className="tdata name"
                          style={{
                            fontSize: '1.3em',
                            fontWeight: '300',
                            fontFamily: 'sans-serif',
                            letterSpacing: '0.5px',
                            marginBottom: '7px',
                          }}
                        >
                          {reservation.fullName}
                        </div>
                        <div
                          className="tinfo"
                          style={{
                            fontSize: '0.7em',
                            fontWeight: '300',
                            color: 'rgba(0, 0, 0, 0.5)',
                            fontFamily: 'sans-serif',
                            textTransform: 'uppercase',
                            letterSpacing: '1px',
                            margin: '0',
                          }}
                        >
                          ticket
                        </div>
                        <div
                          className="tdata"
                          style={{
                            fontSize: '0.8em',
                            fontWeight: '400',
                            fontFamily: 'sans-serif',
                            letterSpacing: '0.5px',
                            margin: '7px 0',
                          }}
                        >
                          {reservation?.ticketId}
                        </div>
                        <div
                          className="tinfo"
                          style={{
                            fontSize: '0.8em',
                            fontWeight: '300',
                            color: 'rgba(0, 0, 0, 0.5)',
                            fontFamily: 'sans-serif',
                            textTransform: 'uppercase',
                            letterSpacing: '1px',
                            margin: '0',
                          }}
                        >
                          event
                        </div>
                        <div
                          className="tdata"
                          style={{
                            fontSize: '0.8em',
                            fontWeight: '400',
                            fontFamily: 'sans-serif',
                            letterSpacing: '0.5px',
                            margin: '7px 0',
                          }}
                        >
                          {reservation?.title}
                        </div>
                        <div
                          className="tinfo"
                          style={{
                            fontSize: '0.8em',
                            fontWeight: '300',
                            color: 'rgba(0, 0, 0, 0.5)',
                            fontFamily: 'sans-serif',
                            textTransform: 'uppercase',
                            letterSpacing: '1px',
                            margin: '0',
                          }}
                        >
                          Number of seats reserved
                        </div>
                        <div
                          className="tdata"
                          style={{
                            fontSize: '0.8em',
                            fontWeight: '400',
                            fontFamily: 'sans-serif',
                            letterSpacing: '0.5px',
                            margin: '7px 0',
                          }}
                        >
                          {reservation?.spotsReserved}
                        </div>
                        <div
                          className="tinfo"
                          style={{
                            fontSize: '0.8em',
                            fontWeight: '300',
                            color: 'rgba(0, 0, 0, 0.5)',
                            fontFamily: 'sans-serif',
                            textTransform: 'uppercase',
                            letterSpacing: '1px',
                            margin: '0',
                          }}
                        >
                          Date
                        </div>
                        <div
                          className="tdata"
                          style={{
                            fontSize: '0.8em',
                            fontWeight: '400',
                            fontFamily: 'sans-serif',
                            letterSpacing: '0.5px',
                            margin: '7px 0',
                          }}
                        >
                          {departureDate}
                        </div>
                        <div
                          className="tinfo"
                          style={{
                            fontSize: '0.8em',
                            fontWeight: '300',
                            color: 'rgba(0, 0, 0, 0.5)',
                            fontFamily: 'sans-serif',
                            textTransform: 'uppercase',
                            letterSpacing: '1px',
                            margin: '0',
                          }}
                        >
                          Price
                        </div>
                        <div
                          className="tdata"
                          style={{
                            fontSize: '0.8em',
                            fontWeight: '400',
                            fontFamily: 'sans-serif',
                            letterSpacing: '0.5px',
                            margin: '7px 0',
                          }}
                        >
                          {reservation.spotsReserved * reservation.price} ETB
                        </div>

                        <div
                          style={{
                            fontSize: '1em',
                            fontWeight: '600',
                            letterSpacing: '0.5px',
                            marginTop: '20px',
                          }}
                        >
                          <a
                            href="https://behiwot.com"
                            rel="noreferrer"
                            target="_blank"
                          >
                            @Behiwot
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                <Box mt={[2, 5]} mx={'auto'} width={['90%', '95%']}>
                  <Flex
                    flexWrap={'wrap'}
                    mx={['auto']}
                    pb={[3, 1]}
                    style={{
                      gap: 10,
                    }}
                    width={['100%']}
                  >
                    <Button
                      background="black.0"
                      borderRadius={[1]}
                      color="white.0"
                      fontSize={['12px']}
                      onClick={handlePrint}
                      px={[3]}
                      py={[1]}
                      variant="primary"
                      width={['90px']}
                    >
                      Print
                    </Button>
                    <Button
                      background="black.0"
                      borderRadius={[1]}
                      color="white.0"
                      fontSize={['12px']}
                      onClick={clickModal}
                      px={[3]}
                      py={[1]}
                      variant="cancel"
                      width={['90px']}
                    >
                      Cancel
                    </Button>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Modal>

          <Flex
            alignContent={['center']}
            backgroundColor={'white.0'}
            borderRadius="10px"
            flexDirection={['column', 'row', 'row']}
            flexWrap={'wrap'}
            height={['100%']}
            justifyContent={['center', 'center', 'space-evenly']}
            mb={['20px']}
            px={[0, '10px']}
            py={[0, '20px']}
            style={{
              gap: '20px',
              boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.08)',
            }}
          >
            <Grad
              borderBottomLeftRadius={['0', '10px']}
              borderBottomRightRadius={['0', '10px']}
              borderRadius={'10px'}
              height={['200px', '250px', '150px']}
              my={['auto']}
              onClick={() => {
                history.push(`/tripDetail/${reservation._id}`);
              }}
              width={['100%', '40%', '20%', '14%']}
            >
              <Image
                alt={`Image for reservation ${reservation.title}`}
                borderBottomLeftRadius={['0', '10px']}
                borderBottomRightRadius={['0', '10px']}
                borderTopLeftRadius={['10px']}
                borderTopRightRadius={['10px']}
                height={'100%'}
                src={reservation.imageId}
                style={{
                  cursor: 'pointer',
                }}
                width={'100%'}
              />
            </Grad>

            <Flex
              alignContent={['space-between']}
              flexDirection={['column']}
              flexWrap={'wrap'}
              justifyContent={['flex-start', 'space-between']}
              mt={['0px']}
              pb={['15px', 0]}
              pl={['5px']}
              px={['10px', 0]}
              style={{
                gap: '10px',
              }}
              width={['100%', '55%', '75%', '80%']}
            >
              <Flex
                alignContent={'center'}
                flexDirection={['row']}
                flexWrap="wrap"
                justifyContent={['space-between']}
                width={['100%', '100%', '90%', '100%']}
              >
                <Flex
                  alignContent={['start']}
                  flexDirection={['row']}
                  flexWrap={['wrap']}
                  justifyContent={['space-between', 'flex-start']}
                  mt={['-10px', 0, 0]}
                  style={{
                    gap: '20px',
                  }}
                  width={['100%', '100%', '80%']}
                >
                  <Box width={['100%', '50%', '50%']}>
                    <Text
                      color="black.1"
                      fontFamily="Poppins"
                      fontSize="18px"
                      fontStyle="normal"
                      fontWeight={[600]}
                      height={['auto']}
                      lineHeight="27px"
                      my={0}
                      onClick={() => {
                        history.push(`/tripDetail/${reservation._id}`);
                      }}
                      py={[0]}
                      style={{
                        textTransform: 'capitalize',
                        cursor: 'pointer',
                      }}
                      variant={'ellipsis'}
                      width={['95%', '80%']}
                    >
                      {reservation.title}
                    </Text>
                    <Box mt={['-5px', '-7px']}>
                      <Text
                        color="black.5"
                        fontSize={['13px']}
                        lineHeight={['20px']}
                        my={0}
                        py={0}
                      >
                        Departure date
                        <Text
                          color={'black.6'}
                          display={['block']}
                          fontSize={['12px']}
                          lineHeight={['13px']}
                        >
                          {new Date(reservation.departureDate).toDateString() +
                            ' ' +
                            new Date(
                              reservation.departureDate,
                            ).toLocaleTimeString('en-us', {
                              minute: '2-digit',
                              hour: '2-digit',
                            })}
                        </Text>
                      </Text>
                    </Box>
                  </Box>
                  <Flex
                    alignContent={['flex-end', 'flex-end', 'flex-start']}
                    flexDirection={['row', 'row', 'row']}
                    flexWrap={['wrap']}
                    justifyContent={['space-between']}
                    mt={['', 0]}
                    width={['100%', '100%', '30%']}
                  >
                    <Box my={0}>
                      {reservation.isDeclined ? (
                        <Text
                          color={'error.0'}
                          fontSize={['14px']}
                          fontWeight={[600]}
                          lineHeight={['20px']}
                          my={0}
                          py={0}
                        >
                          Declined !
                        </Text>
                      ) : reservation.isPaid ? (
                        <Text
                          color={'success.0'}
                          fontSize={['14px']}
                          fontWeight={[600]}
                          lineHeight={['20px']}
                          my={0}
                          py={0}
                        >
                          Paid <RightIcon fill="#19C47C" />
                        </Text>
                      ) : reservation.imageId ? (
                        <Text
                          color={'warning.0'}
                          fontSize={['14px']}
                          fontWeight={[500, 600]}
                          my={0}
                        >
                          Pending
                        </Text>
                      ) : (
                        <Text
                          color={'secondary.2'}
                          fontSize={['14px', '14px']}
                          fontWeight={[500, 600]}
                          lineHeight={['20px']}
                          my={0}
                          onClick={() => {
                            history.push(
                              `/editReservation/${reservation.reservationId}`,
                            );
                          }}
                          style={{
                            cursor: 'pointer',
                            border: '1px solid #FF8058',
                            borderRadius: '5px',
                            padding: '3px 8px',
                          }}
                        >
                          Pay Now
                        </Text>
                      )}
                    </Box>
                    <Box
                      height={['40%', 'auto']}
                      m={0}
                      mr={['10px', 0]}
                      p={0}
                      style={{ textAlign: 'end' }}
                    >
                      <Text
                        color={'black.6'}
                        display={['inline-block']}
                        fontSize={['12px', '13px']}
                        lineHeight={['15px', '20px']}
                        my={0}
                      >
                        {reservation.spotsReserved} spots{' '}
                        <Text lineHeight={'0px'}>reserved</Text>
                      </Text>
                    </Box>
                  </Flex>
                </Flex>
                <Flex
                  alignContent={['end', 'flex-start']}
                  justifyContent={['space-between']}
                  mt={['10px', '10px', 0]}
                  width={['100%', '100%', 'auto']}
                >
                  <Box>
                    <H3
                      color="secondary.2"
                      fontFamily="Poppins"
                      fontSize="16px"
                      fontStyle="normal"
                      fontWeight={[600]}
                      lineHeight="27px"
                      my={0}
                      pb={['5px', 0]}
                      pr={['10px']}
                    >
                      {priceLabeler(reservation.price)} ETB
                    </H3>
                  </Box>
                </Flex>
              </Flex>
              <Flex
                alignContent={'start'}
                flexDirection={['column']}
                justifyContent={'space-between'}
                width={['100%']}
              >
                <Box py={['5px']} width={['90%', '92%']}>
                  <Text
                    color={'black.7'}
                    fontFamily={'Poppins'}
                    fontSize={['14px']}
                    variant={'multiLineEllipsis'}
                    width={['100%']}
                  >
                    {convertToText(props.reservation.shortDescription)}
                  </Text>
                </Box>
                <Flex
                  alignContent={'flex-end'}
                  justifyContent={'space-between'}
                  my={['10px']}
                  pr={[0, '10px']}
                  style={{
                    gap: '10px',
                  }}
                  width={['100%']}
                >
                  <Box>
                    <H3
                      color="black.6"
                      fontFamily="Poppins"
                      fontSize={['14px', '16px']}
                      fontStyle="normal"
                      fontWeight={[600]}
                      lineHeight="27px"
                      my={0}
                      onClick={() => {
                        history.push(`/tripDetail/${reservation._id}`);
                      }}
                      pb={['5px', 0]}
                      pr={['0px', '10px']}
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      Detail
                    </H3>
                  </Box>
                  <Flex
                    alignContent={'flex-end'}
                    justifyContent={'space-between'}
                    my={['10px']}
                    pr={[0, '10px']}
                    style={{
                      gap: '10px',
                    }}
                  >
                    {!reservation.isPaid && (
                      <>
                        <Box
                          height={['20px']}
                          onClick={() => {
                            handleOpenDialog();
                            setTargetReservation({
                              reservationId: reservation.reservationId,
                              title: reservation.title,
                            });
                          }}
                          style={{ cursor: 'pointer' }}
                          width={['20px']}
                        >
                          <DeleteIcon fill="#ee4b2b" />
                        </Box>
                      </>
                    )}
                    {!reservation.isDeclined &&
                      !reservation.isPaid &&
                      reservation.imageId && (
                        <Text
                          height={['20px']}
                          mb={'5px'}
                          ml={'10px'}
                          onClick={() => {
                            history.push(
                              `/editReservation/${reservation.reservationId}`,
                            );
                          }}
                          style={{ cursor: 'pointer' }}
                          width={['20px']}
                        >
                          <EditIcon fill="#485C75" />
                        </Text>
                      )}
                    {reservation.isPaid && (
                      <Text
                        height={['20px']}
                        mb={'5px'}
                        ml={'10px'}
                        onClick={clickModal}
                        style={{ cursor: 'pointer' }}
                        width={['20px']}
                      >
                        <TicketIcon fill="#485C75" />
                      </Text>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </>
      )}
    </>
  );
}
