import React, { useState } from 'react';
import Box from '@mui/material/Box';

import FormControl from '@mui/material/FormControl';
import { InputLabel, Select } from '@mui/material';
import { SelectorProps } from './types';
import { ReactComponent as ChevronUp } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ChevronDownGrey } from 'assets/icons/chevron-down-grey.svg';
import { useFormikContext } from 'formik';
import { theme } from 'styles/theme';

export default function Selector(props: SelectorProps) {
  const { setFieldValue } = useFormikContext();
  const [selectedValue, setselectedValue] = useState<string | undefined>('');

  const handleChange = e => {
    const { value } = e.target;
    if (props.name === 'discount') {
      const selectedValue = e.target.value;
      const selectedOption = props.options?.find(
        option => option.name === selectedValue,
      );
      if (selectedOption) {
        setselectedValue(selectedOption?.name);
        const selectedId = selectedOption._id;
        setFieldValue('discount', selectedId);
      }
    } else {
      setFieldValue(props.name || 'category', value);
    }
  };

  return (
    <Box {...props}>
      <FormControl
        fullWidth
        sx={{
          label: {
            color: '#9DA7BC',
            fontSize: '13.7px',
            fontWeight: 300,
            p: '9px 0 0 10px',
          },

          svg: {
            color: '#FF8058',
            top: 'auto',
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            height: props.selectorHight || '45px',
            backgroundColor: '#F5F5F7',
          },
          '& .MuiInputLabel-root': {
            top: props.selectorTop || '-9px',
            height: ['27px'],
            maxWidth: 'calc(100% - 37px)',
          },
          '& .MuiInputLabel-shrink': {
            color: theme.colors.black[7],
            p: 0,
            top: 0,
          },
          '& .MuiSelect-icon': {
            right: '13.78px',
            top: 'calc(50% - 0.2em)',
          },
          '& .css-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:after': {
            borderBottomColor: 'rgba(0, 0, 0, 0.87)',
          },
          '& .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input:focus':
            {
              backgroundColor: 'transparent',
            },
          '& .MuiSelect-select': {
            paddingLeft: '15px',
            ...(props.variant !== 'standard' && {
              '&::before': {
                content: '""',
                marginRight: '10px',
                borderLeft: '1px solid #FF8058',
              },
            }),
          },
          ...(props.variant === 'standard' && {
            '& .css-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root': {
              color: '#485C75',
              fontFamily: 'Poppins',
              fontSize: '12px',
              textAlign: 'center',
            },
          }),
          ...(!props.showLabel && {
            '& .css-1in441m>span': {
              display: 'none',
            },
          }),
        }}
        variant={props.variant}
      >
        {props.showLabel ? (
          <InputLabel id={props.name || 'category'}>
            {(props.name &&
              props.name?.slice(0, 1).toUpperCase() + props.name?.slice(1)) ||
              'Category'}
          </InputLabel>
        ) : null}
        <Select
          IconComponent={
            props.variant === 'standard' ? ChevronDownGrey : ChevronUp
          }
          defaultValue={props.value}
          id={props.name || 'categtory'}
          label={props.name || 'Caegory'}
          name={props.name || 'category'}
          onChange={handleChange}
          placeholder="something"
          value={selectedValue || props.value}
        >
          {/* {props.disableAll ? null : (
            <MenuItem value={props.variant === 'standard' ? '' : 'All'}>
              {props.variant === 'standard' ? 'None' : 'All'}
            </MenuItem>
          )} */}
          {props.children}
        </Select>
      </FormControl>
    </Box>
  );
}
