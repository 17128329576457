import React, { useState, useEffect } from 'react';
import { CardContainer } from '../Card';
import { Box, Flex } from '../Basics';
import { useHistory } from 'react-router-dom';
import { Button, Image, Text, Grad } from '../../Blocks';
import { CardInfo } from './types';
import { ACTIVITIES, BIRR, REMAINING } from 'app/data';
import { ReactComponent as Delete } from 'assets/icons/delete.svg';
import { ReactComponent as LikeIcon } from 'assets/icons/heart.svg';
import { ReactComponent as FilledLikeIcon } from 'assets/icons/heart-fill.svg';

import Popover from '@mui/material/Popover';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { convertToText, priceLabeler } from 'utils/helpers';

export const Card = (props: CardInfo) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const eventId = React.useRef('');
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [openAlert, setOpen] = React.useState(false);
  const [locationName, setLocationName] = useState('');
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenAlert = ({ id }) => {
    setOpen(true);
    eventId.current = id;
  };
  const handleCloseAlert = () => {
    setOpen(false);
  };
  useEffect(() => {
    const geocoder = new window.google.maps.Geocoder();
    if (props.event?.locationId) {
      geocoder.geocode(
        {
          placeId: props.event.locationId,
        },
        (results, status) => {
          if (status === 'OK' && results && results.length > 0) {
            setLocationName(results[0].formatted_address);
          }
        },
      );
    }
  }, [props.event]);
  const alerMenu = (
    <Box>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleCloseAlert}
        open={openAlert}
      >
        <DialogTitle id="alert-dialog-title">
          <Text
            color={'black.7'}
            fontFamily={'Poppins'}
            fontSize={[1, 2]}
            fontWeight={[3]}
            lineHeight={[2]}
          >
            Are you sure you want to delete this event?
          </Text>
        </DialogTitle>

        <DialogActions>
          <Button color="red" fontSize={[1]} onClick={handleCloseAlert} p={[2]}>
            Cancel
          </Button>
          <Button
            autoFocus
            color="primary.0"
            fontSize={[1]}
            onClick={() => {
              props.deleteEventHandler &&
                props.deleteEventHandler(eventId.current);
              handleCloseAlert();
            }}
            p={[2]}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );

  return (
    <>
      <CardContainer
        mb={['8px']}
        onClick={() => {
          history.push(`/tripDetail/${props.event._id}`);
        }}
        position="relative"
        width={['300px', '255px']}
      >
        <Grad height="197px" width={['300px', '100%']}>
          <Image
            borderRadius="10px 10px 0 0"
            height="100%"
            src={props.event.images[0]}
            width="100%"
          ></Image>
        </Grad>
        <Text
          as="h1"
          color={'white.0'}
          fontFamily={'poppins'}
          fontSize={[0]}
          fontWeight={[6]}
          left="5%"
          lineHeight={[3]}
          position="absolute"
          top="3%"
          variant="ellipsis"
          width={[19]}
        >
          {locationName}
        </Text>

        {props.isOwnProfile ? (
          <Delete
            onClick={e => {
              e.stopPropagation();
              handleOpenAlert({ id: props.event?._id });
            }}
            style={{
              position: 'absolute',
              right: '5%',
              top: '4%',
              cursor: 'pointer',
              opacity: 0.7,
              width: '20px',
              height: '20px',
              color: '#EE4B2B',
            }}
          />
        ) : props.event.isSaved ? (
          <FilledLikeIcon
            onClick={e => {
              props.unSaveHandler && props.unSaveHandler(props.event?._id);
              e.stopPropagation();
            }}
            style={{
              position: 'absolute',
              right: '5%',
              top: '3%',
              cursor: 'pointer',
              opacity: 0.9,
              width: '20px',
              height: '20px',
            }}
          />
        ) : (
          <LikeIcon
            onClick={e => {
              props.saveHandler && props.saveHandler(props.event?._id);
              e.stopPropagation();
            }}
            style={{
              position: 'absolute',
              right: '5%',
              top: '3%',
              cursor: 'pointer',
              opacity: 0.9,
              width: '20px',
              height: '20px',
            }}
          />
        )}

        <Box mx={4} my={2}>
          <Flex justifyContent={'space-between'}>
            <Flex flexDirection={'column'}>
              <Text
                as="h1"
                color={'black.5'}
                fontSize={[1]}
                fontWeight={5}
                lineHeight={[1, 2]}
                pb={1}
                variant="ellipsis"
                width={[25]}
              >
                {props.event.title}
              </Text>
              <Flex>
                <Text
                  color="#C5C5C5"
                  fontSize="10px"
                  fontWeight={4}
                  lineHeight={[1, 2]}
                >
                  {props.event.activitiesCount}
                  &nbsp;{ACTIVITIES}
                </Text>
                <Text
                  color="#C5C5C5"
                  fontSize="10px"
                  fontWeight={4}
                  lineHeight={[1, 2]}
                >
                  &nbsp;| {props.event.category}
                </Text>
              </Flex>
            </Flex>
            <Text
              color={'secondary.0'}
              fontSize={[1]}
              fontWeight={6}
              lineHeight={[3]}
              mt={2}
            >
              {priceLabeler(props.event?.price)} {''}
              {BIRR}
            </Text>
          </Flex>
          <Text
            as="p"
            color={'black.5'}
            fontSize="11px"
            fontWeight={[4]}
            height={'55px'}
            lineHeight={[2, 3]}
            // ml={[1]}
            my={[3]}
            variant="multiLineEllipsis"
            // width={'215px'}
          >
            {convertToText(props.event?.description)}
          </Text>
          <Flex
            alignItems={'center'}
            borderBottom=" 1px solid #E5E5E5"
            justifyContent="space-between"
            pb={[2]}
          >
            <Text
              color={'black.5'}
              fontSize="10px"
              fontWeight={4}
              lineHeight={[1, 2]}
              // mt={1}
            >
              {props.event?.isOverdue ? (
                <Text
                  color={'black.3'}
                  fontSize={[0]}
                  fontWeight={6}
                  lineHeight={[2, 3]}
                >
                  Overdue
                </Text>
              ) : props.event.seatsRemaining !== undefined ? (
                props.event.seatsRemaining === 0 ? (
                  <Text
                    color={'black.3'}
                    fontSize={[0]}
                    fontWeight={6}
                    lineHeight={[2, 4]}
                  >
                    No seat Available
                  </Text>
                ) : (
                  <>
                    <Text
                      color={'secondary.0'}
                      fontSize={[1]}
                      fontWeight={6}
                      lineHeight={[3, 4]}
                    >
                      {props.event.seatsRemaining}
                    </Text>
                    {''} {REMAINING}
                  </>
                )
              ) : (
                <Text
                  color={'black.3'}
                  fontSize={[0]}
                  fontWeight={6}
                  lineHeight={[2, 4]}
                >
                  {' '}
                  Unlimited Seat
                </Text>
              )}
            </Text>
            {props.event?.discount !== '' && (
              <Text
                aria-describedby={id}
                color={'primary.0'}
                fontFamily={'Poppins'}
                fontSize="11px"
                fontWeight={[4]}
                lineHeight={[2, 3]}
                // mt={[2]}
                onClick={e => {
                  handleClick(e);
                  e.stopPropagation();
                }}
              >
                Discount Avaliable
              </Text>
            )}
          </Flex>

          <Flex alignItems={'center'} justifyContent={'space-between'}>
            <Flex
              onClick={e => {
                history.push(`/user/${props.event.organizer._id}`);
                e.stopPropagation();
              }}
            >
              {props.event.organizer.imageId ? (
                <Image
                  borderRadius="50%"
                  height="28px"
                  mt={[2]}
                  src={props.event.organizer.imageId}
                  width="28px"
                ></Image>
              ) : (
                <Box mt={[2]}>
                  <Flex
                    alignItems="center"
                    backgroundColor={'secondary.0'}
                    borderRadius={'50%'}
                    color="#fff"
                    height={'28px'}
                    justifyContent="center"
                    width={'28px'}
                  >
                    <Text fontSize={'15px'}>
                      {/* {props.event.organizer?.firstName
                        .slice(0, 1)
                        .toUpperCase()} */}
                      {props.event?.organizerName
                        ? `${props.event?.organizerName
                            .slice(0, 1)
                            .toUpperCase()}`
                        : `${props.event?.organizer.firstName
                            .slice(0, 1)
                            .toUpperCase()}`}
                    </Text>
                  </Flex>
                </Box>
              )}

              <Text
                color={'black.5'}
                fontSize={1}
                fontWeight={5}
                lineHeight={[1, 2]}
                m={3}
                variant="ellipsis"
                width={'80px'}
              >
                {props.event?.organizerName
                  ? `${props.event?.organizerName}`
                  : `${props.event?.organizer.firstName}`}
                {/* {props.event.organizer.firstName} */}
              </Text>
            </Flex>

            <Box mt={1}>
              {props.event?.isAccepted ? (
                props.event?.isOverdue ? (
                  <Button
                    backgroundColor={'primary.2'}
                    borderRadius={[1]}
                    color={'white.0'}
                    disabled={true}
                    fontSize={['11px', '10px', '11px', '12px']}
                    fontWeight={[4, 5]}
                    height={['25px', '30px']}
                    opacity={'0.6'}
                    width={['68px', '75px']}
                  >
                    Overdue
                  </Button>
                ) : props.event.payOnArrival ? (
                  <Text
                    color={'primary.3'}
                    fontSize={[1]}
                    fontWeight={6}
                    title="Pay on arrival"
                  >
                    POA
                  </Text>
                ) : (
                  <Button
                    borderRadius={[1]}
                    disabled={props.event.seatsRemaining === 0 ? true : false}
                    fontSize={['12px', '10px', '11px', '12px']}
                    fontWeight={[4, 5]}
                    height={['28px', '30px']}
                    onClick={e => {
                      history.push(`/reservation/${props.event._id}`);
                      e.stopPropagation();
                    }}
                    opacity={props.event.seatsRemaining === 0 ? '0.6' : '1'}
                    variant="primary"
                    width={['75px', '75px']}
                  >
                    Book Now
                  </Button>
                )
              ) : (
                <Button
                  backgroundColor={'black.5'}
                  borderRadius={[1]}
                  color={'white.0'}
                  fontSize={['9px', '10px', '11px', '12px']}
                  fontWeight={[4, 5]}
                  height={['25px', '30px']}
                  width={['68px', '75px']}
                >
                  Pending
                </Button>
              )}
            </Box>
          </Flex>
        </Box>
      </CardContainer>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        id={id}
        onClose={handleClose}
        open={open}
      >
        <Box
          backgroundColor={'white.0'}
          borderRadius={[1]}
          pt={[3]}
          px={[3]}
          width={'201px'}
        >
          <Text
            fontFamily={'Poppins'}
            fontSize={[0]}
            fontWeight={[4]}
            lineHeight={[1]}
          >
            {props.event?.discount}
          </Text>
          {/* {props.event?.discounts &&
            props.event?.discounts.map((discount, index) => {
              return (
                <Flex justifyContent={'space-between'} key={index}>
                  <Text
                    fontFamily={'Poppins'}
                    fontSize={[0]}
                    fontWeight={[4]}
                    lineHeight={[1]}
                    mb={[4]}
                  >
                    {discount.people} people
                  </Text>
                  <Text
                    fontFamily={'Poppins'}
                    fontSize={[0]}
                    fontWeight={[4]}
                    lineHeight={[1]}
                  >
                    {discount.price} %
                  </Text>
                </Flex>
              );
            })} */}
        </Box>
      </Popover>
      {alerMenu}
    </>
  );
};
