import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';

import '../styles/fonts.css';
import { GlobalStyle } from 'styles/global-styles';
import 'react-quill/dist/quill.snow.css';

import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { useTranslation } from 'react-i18next';
import { HomePage } from './pages/HomePage/Loadable';
import { LandingPage } from './pages/LandingPage/Loadable';
import { ReservationListPage } from './pages/ReservationListPage/Loadable';
import { IndividualEventReservationListPage } from './pages/IndividualEventReservationListPage/Loadable';
import { UserReservationListPage } from './pages/UserReservationListPage/Loadable';
import { TripDetailPage } from './pages/TripDetailPage/loadable';
import { LoginPage } from './pages/LoginPage/Loadable';
import { SignupPage } from './pages/SignupPage/Loadable';
import { CreateTripPage } from './pages/CreateTripPage/Loadable';
import { EditTripPage } from './pages/EditTripPage/Loadable';
import { DefaultLayout } from './pages/DefaultLayouts';
import { OrganizerPage } from './pages/OrganizerPage/loadable';
import { SearchResultPage } from './pages/SearchResult/loadable';
import { ActivateAccountPage } from './pages/ActivateAccountPage';
import { ReservationPage } from './pages/ReservationPage/Loadable';
import { EditReservationPage } from './pages/EditReservationPage/Loadable';
import { EditProfilePage } from './pages/EditProfilePage/Loadable';
import { SavedEventsPage } from './pages/SavedEventsPage/loadable';
import { ResetPasswordPage } from './pages/ResetPasswordPage';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage';
import { TermAndConditions } from './pages/Term and Conditions/Loadable';
import { AboutUs } from './pages/About Us/Loadable';
import { SpecialTripListPage } from './pages/SpecialTripListPage';
import { FAQ } from './pages/FAQ/Loadable';
import { subAccountPage } from './pages/subAccount/Loadable';

const ProtectedRoute = props => {
  if (!localStorage.getItem('token')) {
    return <Redirect to="/login" />;
  }
  return <Route {...props} />;
};

const RedirectIfLoggedInRoute = ({ path, redirect = '/', ...props }) => {
  if (localStorage.getItem('token')) {
    return <Redirect to={redirect} />;
  }
  return <Route path={path} {...props} />;
};

export function App() {
  const { i18n } = useTranslation();
  return (
    <BrowserRouter>
      <Helmet
        defaultTitle="Behiwot"
        htmlAttributes={{ lang: i18n.language }}
        titleTemplate="%s - Behiwot"
      >
        <meta content="Behiwot application" name="description" />
      </Helmet>
      <GlobalStyle />
      <DefaultLayout>
        <Switch>
          <Route component={HomePage} exact path="/components" />
          <Route component={LandingPage} exact path="/" />
          <ProtectedRoute
            component={IndividualEventReservationListPage}
            exact
            path="/individualEventReservationList/:eventId"
          />
          <ProtectedRoute
            component={ReservationListPage}
            exact
            path="/reservationList"
          />
          <ProtectedRoute
            component={UserReservationListPage}
            exact
            path="/userReservationList"
          />
          <ProtectedRoute
            component={SpecialTripListPage}
            exact
            path="/specialTripList"
          />
          <Route component={TripDetailPage} exact path="/tripDetail/:eventId" />
          <Route component={HomePage} exact path="/" />
          <RedirectIfLoggedInRoute component={LoginPage} exact path="/login" />
          <RedirectIfLoggedInRoute
            component={SignupPage}
            exact
            path="/signup"
          />
          <ProtectedRoute component={CreateTripPage} exact path="/createTrip" />
          <ProtectedRoute
            component={EditTripPage}
            exact
            path="/editTrip/:eventId"
          />
          <Route component={OrganizerPage} exact path="/user/:userId" />
          <Route component={SearchResultPage} exact path="/search" />
          <Route
            component={TermAndConditions}
            exact
            path="/term-and-conditions"
          />
          <Route component={AboutUs} exact path="/about" />
          <Route component={FAQ} exact path="/faq" />
          <ProtectedRoute
            component={ReservationPage}
            exact
            path="/reservation/:eventId"
          />
          <ProtectedRoute
            component={EditReservationPage}
            exact
            path="/editReservation/:reservationId"
          />
          <RedirectIfLoggedInRoute
            component={ActivateAccountPage}
            exact
            path="/activate"
          />
          <ProtectedRoute
            component={EditProfilePage}
            exact
            path="/editProfile/:userId"
          />
          <ProtectedRoute component={subAccountPage} exact path="/subAccount" />
          <ProtectedRoute
            component={SavedEventsPage}
            exact
            path="/savedEvents"
          />
          <RedirectIfLoggedInRoute
            component={ResetPasswordPage}
            exact
            path="/reset-password"
          />
          <RedirectIfLoggedInRoute
            component={ForgotPasswordPage}
            exact
            path="/forgot-password"
          />
          <Route component={NotFoundPage} />
        </Switch>
      </DefaultLayout>
    </BrowserRouter>
  );
}
