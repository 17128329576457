import React from 'react';
import { Flex, Box } from '../../Basics';
import { Skeleton } from '.././index';

//reservation info skeleton
export default function ReservationInfoSkeleton() {
  return (
    <Flex
      alignContent={['center']}
      border="1px solid #ff7f5847"
      borderRadius={[1, 2, '10px']}
      flexDirection={['column', 'row', 'row']}
      flexWrap="wrap"
      justifyContent={['space-between', 'space-around', 'space-around']}
      ml={['25px', '50px', '70px']}
      px={[2, 1, 1, 5]}
      py={[3, 6, '50px']}
      width={['80%', '75%']}
    >
      <Box>
        <Skeleton
          animation="pulse"
          height={['10px', '20px']}
          variant="text"
          width={'100px'}
        />
      </Box>
      <Box>
        <Skeleton
          animation="pulse"
          height={['10px', '20px']}
          variant="text"
          width={'100px'}
        />
      </Box>
      <Box>
        <Skeleton
          animation="pulse"
          height={['10px', '20px']}
          variant="text"
          width={'100px'}
        />
      </Box>
    </Flex>
  );
}
