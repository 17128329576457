import React from 'react';
import { Flex, Box } from 'app/components/Blocks';
import { Skeleton } from '@mui/material';

const TableSlice = ({ titleWidth, subTitleWidth }) => {
  return (
    <Flex
      border="0.3px solid lightgray"
      flexDirection={'column'}
      height={'15%'}
      justifyContent="center"
      width={'100%'}
    >
      <Box ml={[2, 3]}>
        <Skeleton height={35} variant="text" width={titleWidth} />
        <Skeleton height={20} variant="text" width={subTitleWidth} />
      </Box>
    </Flex>
  );
};

export const DetailLoadingSkeleten = () => {
  return (
    <Flex
      alignItems="center"
      flexDirection={'column'}
      height={'100%'}
      justifyContent="center"
      mt={'7%'}
      width="100%"
    >
      <Flex
        flexDirection={['column', 'column', 'row']}
        flexWrap={'wrap'}
        justifyContent="space-around"
        mb={['3%']}
        width={['100%', '100%', '100%', '90%']}
      >
        {/* image Container Skeleton */}
        <Flex
          alignItems={['center', 'center', 'start']}
          flexDirection={['column']}
          mb={[5]}
          width={['100%', '100%', '50%']}
        >
          <Skeleton height={35} variant="text" width={150} />
          <Box
            height={[265]}
            mb={[3, 4, 5]}
            mt={'8%'}
            width={[310, 400, 430, 515]}
          >
            <Skeleton
              height={'100%'}
              variant="rectangular"
              width={'100%'}
            ></Skeleton>
          </Box>
          <Flex>
            <Box
              height={[100, 157]}
              mr={[2, 3, 4, 5]}
              width={[150, 200, 210, 245]}
            >
              <Skeleton
                height={'100%'}
                variant="rectangular"
                width={'100%'}
              ></Skeleton>
            </Box>
            <Box
              height={[100, 157]}
              mb={[4, 5, 6]}
              width={[150, 180, 210, 245]}
            >
              <Skeleton
                height={'100%'}
                variant="rectangular"
                width={'100%'}
              ></Skeleton>
            </Box>
          </Flex>
        </Flex>

        {/* display Table info skeleton */}
        <Flex flexDirection="column" mb={[5]} width={['100%', '100%', '50%']}>
          <Flex borderRadius={'50%'} justifyContent="end" mb={'2%'}>
            <Box display={['none', 'none', 'block']} mr={[2, 3]}>
              <Skeleton height={50} variant="circular" width={50} />
            </Box>
            <Skeleton height={35} variant="text" width={85} />
          </Flex>
          <Flex
            border="0.3px solid lightgray"
            flexDirection={'column'}
            height={'35%'}
            justifyContent="center"
            width={'100%'}
          >
            <Flex width="100%">
              <Box ml={[2, 3]} width="100%">
                <Box mb={'2%'} width="100%">
                  <Flex justifyContent="space-between" width={'100%'}>
                    <Skeleton height={35} variant="text" width={120} />
                    <Box mr={'3%'}>
                      <Skeleton height={30} variant="text" width={70} />
                    </Box>
                  </Flex>
                  <Skeleton height={20} variant="text" width={60} />
                  <Skeleton height={20} variant="text" width={200} />
                </Box>
                <Skeleton height={35} variant="text" width={180} />
                <Skeleton height={20} variant="text" width={120} />
              </Box>
            </Flex>
          </Flex>
          <TableSlice subTitleWidth={150} titleWidth={70} />
          <TableSlice subTitleWidth={80} titleWidth={100} />
          <TableSlice subTitleWidth={150} titleWidth={70} />
          <TableSlice subTitleWidth={25} titleWidth={120} />
          <TableSlice subTitleWidth={150} titleWidth={60} />
        </Flex>
      </Flex>
      <Skeleton height={200} variant="rectangular" width={'85%'} />
    </Flex>
  );
};

export const ReviewsLoadingSkeleton = () => {
  return <Skeleton height={200} variant="rectangular" width={'98%'} />;
};
export const WeatherLoadingSkeleton = () => {
  return <Skeleton height={50} variant="rectangular" width={50} />;
};
