import React from 'react';
import { Flex, Box } from '../../Basics';
import { Skeleton } from '../index';

export const ReservationCardSkeleton = () => (
  <Flex
    alignContent={['center']}
    borderRadius="10px"
    flexWrap={'wrap'}
    height={['100%']}
    justifyContent={['center', 'start']}
    mb={['20px']}
    px={['10px']}
    py={['30px']}
    style={{
      gap: '30px',
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.08)',
    }}
  >
    <Box borderRadius={'10px'} width={['100%', '130px']}>
      <Skeleton
        animation="wave"
        height={['150px', '120px']}
        variant="rectangular"
        width={'100%'}
      />
    </Box>
    <Flex
      alignContent={['space-between']}
      flexDirection={['column']}
      flexWrap={'wrap'}
      justifyContent={['center', 'space-evenly']}
      style={{
        gap: '10px',
      }}
      width={['100%', '65%', '75%', '80%']}
    >
      <Flex
        alignContent={'center'}
        flexDirection={['row']}
        flexWrap="wrap"
        justifyContent={['space-between']}
        width={['100%', '100%', '90%', '100%']}
      >
        <Flex
          alignContent={['start']}
          flexDirection={['column', 'row']}
          flexWrap={['wrap']}
          justifyContent={['space-between', 'space-between']}
          style={{
            gap: '5px',
          }}
          width={['600px']}
        >
          <Box>
            <Skeleton
              animation="wave"
              height={['10px', '20px']}
              variant="text"
              width={'60px'}
            />
            <Skeleton
              animation="wave"
              height={['10px', '20px']}
              variant="text"
              width={'60px'}
            />
          </Box>
          <Box>
            <Skeleton
              animation="wave"
              height={['10px', '20px']}
              variant="text"
              width={'100px'}
            />
          </Box>
          <Box>
            <Skeleton
              animation="wave"
              height={['10px', '20px']}
              variant="text"
              width={'100px'}
            />
          </Box>
        </Flex>
        <Box py={['5px', 0]}>
          <Skeleton
            animation="wave"
            height={'100%'}
            variant="text"
            width={'80px'}
          />
        </Box>
      </Flex>
      <Box width={['100%', '95%']}>
        <Skeleton
          animation="wave"
          height={['10px', '20px']}
          variant="text"
          width={'100%'}
        />
        <Skeleton
          animation="wave"
          height={['10px', '20px']}
          variant="text"
          width={'100%'}
        />
        <Skeleton
          animation="wave"
          height={['10px', '20px']}
          variant="text"
          width={'90%'}
        />
      </Box>
    </Flex>
  </Flex>
);
