import ForgotPasswordPageComponent from 'app/components/ForgotPasswordPage';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { initialValues } from './constants';
import { useForgotPasswordPageSlice } from './slice';
import {
  selectError,
  selectIsEmailSent,
  selectIsSendingEmail,
} from './slice/selector';
import { FormValues } from './types';
import { validationSchema } from './validators';

export function ForgotPasswordPage() {
  const dispatch = useDispatch();
  const { actions } = useForgotPasswordPageSlice();
  const isEmailSent = useSelector(selectIsEmailSent);
  const isSendingEmail = useSelector(selectIsSendingEmail);
  const failureError = useSelector(selectError);

  function onSubmit(values: FormValues) {
    dispatch(actions.sendEmail(values));
  }

  return (
    <>
      <Helmet title="Recover account" />
      <ForgotPasswordPageComponent
        failureError={failureError}
        initialValues={initialValues}
        isEmailSent={isEmailSent}
        isSendingEmail={isSendingEmail}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      />
    </>
  );
}
