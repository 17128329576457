import * as React from 'react';
import { IconedButtonProps } from './types';
import { Button } from './Button';

export const IconedButton = (props: IconedButtonProps) => {
  return (
    <Button {...props}>
      {props.children}
      {props.icon && typeof props.icon !== 'string' && (
        <props.icon
          style={{
            paddingRight: props.iconRightPadding,
            paddingLeft: props.iconLeftPadding,
            margin: props.iconMargin,
            paddingBottom: props.iconBottomPadding,
            width: props.icon_width,
            height: props.icon_height,
          }}
        />
      )}
    </Button>
  );
};
