import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import API from 'API';
import { activateAccountPageActions as actions } from '.';

function* handleActivateAccount(action: PayloadAction<any>) {
  try {
    let data = action.payload;
    const res: AxiosResponse = yield call(API, {
      method: 'POST',
      route: '/user/activate-account',
      payload: data,
    });

    yield put({ type: actions.activateAccountSuccess.type, payload: res.data });
    localStorage.setItem('token', `${res.data.token}`);
    localStorage.setItem('id', `${res.data._id}`);
    localStorage.setItem('email', `${res.data.email}`);
    localStorage.setItem('firstName', `${res.data.firstName}`);
    localStorage.setItem('profileImage', `${res.data.imageId}`);
  } catch (error) {
    yield put({ type: actions.activateAccountFailed.type, payload: error });
  }
}

export function* ActivateAccountPageSaga() {
  yield takeLatest(actions.activateAccount.type, handleActivateAccount);
}
