import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { FormValues } from '../types';
import { ForgotPasswordPageSaga } from './saga';
import { ForgotPasswordPageState } from './types';

export const initialState: ForgotPasswordPageState = {
  isSendingEmail: false,
  isEmailSent: false,
};

const slice = createSlice({
  name: 'forgotPasswordPage',
  initialState,
  reducers: {
    // eslint-disable-next-line no-unused-vars
    sendEmail: (state, action: PayloadAction<FormValues>) => {
      state.isSendingEmail = true;
    },
    sendEmailSuccess: state => {
      state.isSendingEmail = false;
      state.isEmailSent = true;
    },
    sendEmailFailed: (state, action: PayloadAction<string>) => {
      state.isSendingEmail = false;
      state.isEmailSent = false;
      state.error = action.payload;
    },
  },
});

export const { actions: forgotPasswordPageActions } = slice;

export const useForgotPasswordPageSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: ForgotPasswordPageSaga });
  return { actions: slice.actions };
};
