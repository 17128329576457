import React from 'react';
import { Flex, Box } from 'app/components/Blocks';
import { Skeleton } from '@mui/material';
const FeaturedSectionSkeleton = () => {
  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      height="100%"
      justifyContent={'center'}
      width="100%"
    >
      <Flex height={'100%'} justifyContent="center">
        <Box height={[13, 15]} width={[170, 230]}>
          <Skeleton height={'100%'} variant="text" width={'100%'} />
        </Box>
      </Flex>
      <Flex height={'100%'} mb={'4%'}>
        <Box height={8} width={[250, 330]}>
          <Skeleton height={'100%'} variant="text" width={'100%'} />
        </Box>
      </Flex>
      <Flex
        alignItems="center"
        flexDirection="column"
        height={'100%'}
        mb={'5%'}
      >
        <Box height={4} width={[300, 500]}>
          <Skeleton height={'100%'} variant="text" width={'100%'} />
        </Box>
        <Box height={4} width={[200, 400]}>
          <Skeleton height={'100%'} variant="text" width={'100%'} />
        </Box>
        <Box height={4} width={[300, 500]}>
          <Skeleton height={'100%'} variant="text" width={'100%'} />
        </Box>
      </Flex>
      <Flex borderRadius={'50%'} justifyContent="end" mb={'2%'}>
        <Box display={['none', 'block']} mr={[2, 3]}>
          <Skeleton height={50} variant="circular" width={50} />
        </Box>
        <Flex alignItems="center" flexDirection="column">
          <Skeleton height={35} variant="text" width={85} />
          <Skeleton height={20} variant="text" width={150} />
          <Flex justifyContent="space-between" mt={'5%'} width="60%">
            <Skeleton height={15} variant="circular" width={15} />
            <Skeleton height={15} variant="circular" width={15} />
            <Skeleton height={15} variant="circular" width={15} />
            <Skeleton height={15} variant="circular" width={15} />
            <Skeleton height={15} variant="circular" width={15} />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FeaturedSectionSkeleton;
