import React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Box } from '../Basics';

export const BasicTime = props => {
  const {
    bgColor,
    border,
    inputColor,
    inputMl,
    display,
    flexDirection,
    inputFormat,
  } = props;
  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          inputFormat={inputFormat || 'hh:mm a'}
          label={props.placeholder}
          onChange={props.handleChange}
          renderInput={params => (
            <TextField
              {...params}
              sx={{
                button: {
                  boxShadow: 'inset 0 0 20px 10px #FFF4F0',
                },
                svg: { color: '#FF8058' },
                input: {
                  color: inputColor || '',
                  marginLeft: inputMl || '0px',
                },
                label: {
                  color: inputColor || '#656A71',
                  fontSize: '12px',
                  marginLeft: inputMl || '0px',
                  display: display || 'initial',
                },
                '& .css-68a4hh-MuiInputBase-root-MuiOutlinedInput-root': {
                  flexDirection: flexDirection || 'row',
                  gap: '0px',
                  ml: '10px',
                },
                '& .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-6oa2s8-MuiInputBase-root-MuiOutlinedInput-root':
                  {
                    backgroundColor: bgColor || '#F5F5F7',
                    height: props.height || '45px',
                    borderRadius: '10px',
                  },
                '.css-1d3z3hw-MuiOutlinedInput-notchedOutline ': {
                  border: border || '1px solid rgba(0, 0, 0, 0.23)',
                },
              }}
            />
          )}
          value={props.value}
        />
      </LocalizationProvider>
    </Box>
  );
};
