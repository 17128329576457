import { Skeleton } from '@mui/material';
import React from 'react';
import { Box, Flex, MobileWrapper, OnlyDesktopWrapper } from '../../Basics';

const OrganizerSkeleton = () => {
  return (
    <Box>
      <OnlyDesktopWrapper>
        <Flex flexWrap="wrap" justifyContent="center">
          <Flex flexDirection="column" mb={[6, 7, 8]} mr={[6, 7, 8]}>
            <Box mb={'10px'}>
              <Skeleton height="80px" variant="circular" width="80px" />
            </Box>
            <Skeleton height="15px" variant="text" width="85px" />
            <Skeleton height="15px" variant="text" width="85px" />
          </Flex>
          <Flex flexDirection="column" mb={[6, 7, 8]} mr={[6, 7, 8]}>
            <Box mb={'10px'}>
              <Skeleton height="80px" variant="circular" width="80px" />
            </Box>
            <Skeleton height="15px" variant="text" width="85px" />
            <Skeleton height="15px" variant="text" width="85px" />
          </Flex>
          <Flex flexDirection="column" mb={[6, 7, 8]} mr={[6, 7, 8]}>
            <Box mb={'10px'}>
              <Skeleton height="80px" variant="circular" width="80px" />
            </Box>
            <Skeleton height="15px" variant="text" width="85px" />
            <Skeleton height="15px" variant="text" width="85px" />
          </Flex>
          <Flex flexDirection="column" mb={[6, 7, 8]} mr={[6, 7, 8]}>
            <Box mb={'10px'}>
              <Skeleton height="80px" variant="circular" width="80px" />
            </Box>
            <Skeleton height="15px" variant="text" width="85px" />
            <Skeleton height="15px" variant="text" width="85px" />
          </Flex>
          <Flex flexDirection="column" mb={[6, 7, 8]} mr={[6, 7, 8]}>
            <Box mb={'10px'}>
              <Skeleton height="80px" variant="circular" width="80px" />
            </Box>
            <Skeleton height="15px" variant="text" width="85px" />
            <Skeleton height="15px" variant="text" width="85px" />
          </Flex>
          <Flex flexDirection="column" mb={[6, 7, 8]} mr={[6, 7, 8]}>
            <Box mb={'10px'}>
              <Skeleton height="80px" variant="circular" width="80px" />
            </Box>
            <Skeleton height="15px" variant="text" width="85px" />
            <Skeleton height="15px" variant="text" width="85px" />
          </Flex>
          <Flex flexDirection="column" mb={[6, 7, 8]} mr={[6, 7, 8]}>
            <Box mb={'10px'}>
              <Skeleton height="80px" variant="circular" width="80px" />
            </Box>
            <Skeleton height="15px" variant="text" width="85px" />
            <Skeleton height="15px" variant="text" width="85px" />
          </Flex>
        </Flex>
      </OnlyDesktopWrapper>
      <MobileWrapper>
        <Flex justifyContent={'center'}>
          <Flex flexDirection="column" mb={[6, 7, 8]} mr={[6, 7, 8]}>
            <Box mb={'10px'}>
              <Skeleton height="80px" variant="circular" width="80px" />
            </Box>
            <Skeleton height="15px" variant="text" width="85px" />
            <Skeleton height="15px" variant="text" width="85px" />
          </Flex>
          <Flex flexDirection="column" mb={[6, 7, 8]} mr={[6, 7, 8]}>
            <Box mb={'10px'}>
              <Skeleton height="80px" variant="circular" width="80px" />
            </Box>
            <Skeleton height="15px" variant="text" width="85px" />
            <Skeleton height="15px" variant="text" width="85px" />
          </Flex>
          <Flex flexDirection="column" mb={[6, 7, 8]} mr={[6, 7, 8]}>
            <Box mb={'10px'}>
              <Skeleton height="80px" variant="circular" width="80px" />
            </Box>
            <Skeleton height="15px" variant="text" width="85px" />
            <Skeleton height="15px" variant="text" width="85px" />
          </Flex>
        </Flex>
      </MobileWrapper>
    </Box>
  );
};

export default OrganizerSkeleton;
