import React from 'react';
import { Field } from 'formik';

export const TextArea = props => {
  return (
    <Field
      {...props}
      as="textarea"
      style={{
        height: '100%',
        padding: '10px',
        background: '#F7F7F8',
        border: '1px solid #C4C4C4',
        borderRadius: '5px',
        width: '100%',
        ':focus': {
          outline: 'none',
          boxShadow: '0px 0px 4px #1D7EF0',
        },
      }}
    />
  );
};
