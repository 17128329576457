import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
	*, *::after, *::before {
		font-family: 'Poppins'
	}

  html,
  body {
    height: 100%;
    width: 100%;
    background-color: #FBFBFB;
  }
  
 
  body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    box-sizing: border-box;
    width: 100%;
  }

	a {
		text-decoration: none;
	}

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  
  label {
    font-family: 'Mulish', sans-serif;
    line-height: 1.5em;
  }

  input{
    font-family: 'Nunito', sans-serif;
  }

  textarea{
    :focus {
      outline: none;
      box-shadow: 0px 0px 4px #1D7EF0;
    }
  }

  select {
    font-family: inherit;
    font-size: inherit;
  }
`;
