import { PayloadAction } from '@reduxjs/toolkit';
import API from 'API';
import { call, put, takeLatest } from 'redux-saga/effects';
import { forgotPasswordPageActions as actions } from '.';
import { FormValues } from '../types';

function* handleSendingEmail(action: PayloadAction<FormValues>) {
  try {
    yield call(API, {
      method: 'POST',
      route: '/user/forgot-password',
      payload: action.payload,
    });
    yield put({ type: actions.sendEmailSuccess.type });
  } catch (error) {
    yield put({ type: actions.sendEmailFailed.type, payload: error });
  }
}

export function* ForgotPasswordPageSaga() {
  yield takeLatest(actions.sendEmail.type, handleSendingEmail);
}
