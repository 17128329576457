import * as Yup from 'yup';
import { errorMessages } from './constants';

export const validationSchema = Yup.object({
  password: Yup.string()
    .min(8, errorMessages.password.min)
    .max(60, errorMessages.password.max)
    .required(errorMessages.password.required)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*/])(?=.{8,})/,
      errorMessages.password.invalid,
    ),
  confirmPassword: Yup.string()
    .required(errorMessages.confirmPassword.required)
    .oneOf([Yup.ref('password'), null], errorMessages.confirmPassword.invalid),
});
