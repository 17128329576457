import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { ResetPasswordPageSaga } from './saga';
import { IPayload, ResetPasswordPageState } from './types';

export const initialState: ResetPasswordPageState = {
  isResettingPassword: false,
  isPasswordReset: false,
  isValidUrl: false,
};

const slice = createSlice({
  name: 'resetPasswordPage',
  initialState,
  reducers: {
    // eslint-disable-next-line no-unused-vars
    resetPassword: (state, action: PayloadAction<IPayload>) => {
      state.isResettingPassword = true;
    },
    resetPasswordSuccess: state => {
      state.isResettingPassword = false;
      state.isPasswordReset = true;
    },
    resetPasswordFailed: (state, action: PayloadAction<string>) => {
      state.isResettingPassword = false;
      state.isPasswordReset = false;
      state.error = action.payload;
    },
    setIsValidUrl: (state, action: PayloadAction<boolean>) => {
      state.isValidUrl = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const { actions: resetPasswordPageActions } = slice;

export const useResetPasswordPageSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: ResetPasswordPageSaga });
  return { actions: slice.actions };
};
