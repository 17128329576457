import * as React from 'react';
import { Box } from '../Basics';
import { Dialog } from '@mui/material';
import { ModalProps } from './types';
import { theme } from 'styles/theme';

export default function BasicModal(props: ModalProps) {
  const { open, onClose, bgColor, borderRadius, opacity } = props;
  return (
    <Box>
      <Dialog
        fullScreen={props.fullScreen}
        maxWidth={'md'}
        onClose={onClose}
        open={open || false}
        sx={{
          '& .MuiPaper-root': {
            overflowY: props.overflowY || 'initial',
            borderRadius: '10px',
            backgroundColor: `rgba(255, 255, 255,${opacity || 1})`,
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12)',
          },
          '& #scrollArea': {
            overflowY: 'auto',
            paddingRight: ['10px', '12px', '13px', '14px'],
          },
          '& #scrollArea::-webkit-scrollbar': {
            width: '4px',
          },
          '& #scrollArea::-webkit-scrollbar-track': {},
          '& #scrollArea::-webkit-scrollbar-thumb': {
            background: `${theme.colors.secondary[0]}`,
            borderRadius: '3px',
            boxShadow: 'none',
          },
          '& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthMd.css-13mfdaq-MuiPaper-root-MuiDialog-paper':
            {
              borderRadius: borderRadius || '4px',
            },
          '& .css-giosv7-MuiPaper-root-MuiDialog-paper': {
            backgroundColor: bgColor || '#fff',
          },
        }}
      >
        {props.children}
      </Dialog>
    </Box>
  );
}
