import styled from 'styled-components';
import { Field } from 'formik';
import {
  compose,
  border,
  borderRadius,
  color,
  fontSize,
  fontWeight,
  layout,
  space,
  lineHeight,
  fontFamily,
  boxShadow,
  flexbox,
} from 'styled-system';
import { theme } from 'styles/theme';
import { InputProps } from './types';

export const StyledInput = styled(Field)<InputProps>`
  color: ${theme.colors.black[1]};
  background-color: ${theme.colors.white[12]};
  border: 1.5px solid ${theme.colors.black[3]};
  border-radius: 8px;
  font-size: ${theme.fontSizes[1]};
  line-height: ${theme.lineHeights[2]};
  &:focus {
    outline: none;
    border-color: ${theme.colors.secondary[5]};
    border-width: 2px;
  }
  ${compose(
    border,
    borderRadius,
    color,
    fontSize,
    fontWeight,
    layout,
    space,
    lineHeight,
    fontFamily,
    boxShadow,
    flexbox,
  )};
`;
