import API from 'API';
import { AxiosResponse } from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { specialTripListActions } from '.';
import { ISpecialEventInfo } from './types';

//worker
function* getSpecialEventsSaga(action) {
  try {
    const res: AxiosResponse<any> = yield call(API, {
      method: 'GET',
      route: '/special-trip/requests',
      params: {
        page: action.payload.page,
        size: action.payload.size,
      },
      token: localStorage.getItem('token'),
    });
    if (res.status === 200) {
      const data: ISpecialEventInfo = {
        total: res.data.totalCount,
        eventsReserved: [
          {
            data: res.data.data,
            page: action.payload.page,
          },
        ],
      };
      yield put(specialTripListActions.getSpecialTripListSuccess(data));
    }
  } catch (error) {
    yield put(specialTripListActions.getSpecialTripListError());
  }
}

function* confirmSpecialTripSaga(action) {
  const { payload, confirmType, targetId } = action.payload;
  try {
    const res: AxiosResponse<any> = yield call(API, {
      method: 'PUT',
      route: '/special-trip/' + targetId,
      params: {
        id: targetId,
      },
      payload: {
        isAccepted: confirmType === 'Accept' ? true : false,
        isPending: false,
      },
      token: localStorage.getItem('token'),
    });
    if (res.status === 200) {
      if (confirmType === 'Accept') {
        yield put(
          specialTripListActions.handleSpecialTripConfirm(
            'Special Trip Accepted successfully.',
          ),
        );
      } else if (confirmType === 'Decline') {
        yield put(
          specialTripListActions.handleSpecialTripConfirm(
            'Special Trip Declined successfully.',
          ),
        );
      }
      yield put(
        specialTripListActions.handleSpecialTripConfirmSuccess(payload),
      );
    }
  } catch (error: any) {
    if (typeof error == typeof {}) {
      yield console.log('Error ===>', error);
      yield put(
        specialTripListActions.handleSpecialTripConfirmError('server error'),
      );
    } else if (typeof error == typeof '') {
      yield put(specialTripListActions.handleSpecialTripConfirmError(error));
    }
  }
}

//watcher
export function* specialTripListPageSaga() {
  yield takeEvery(
    specialTripListActions.getSpecialTripList.type,
    getSpecialEventsSaga,
  );
  yield takeEvery(
    specialTripListActions.confirmSpecialTrip.type,
    confirmSpecialTripSaga,
  );
}
