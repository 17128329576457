import { CircularProgress } from '@mui/material';
import React from 'react';
import { Flex, Text } from '../Blocks';
import { theme } from '../../../styles/theme';
import { ActivateAccountComponentProp } from './types';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check-circle-fill.svg';

const ActivateAccountPage = (props: ActivateAccountComponentProp) => {
  return (
    <Flex alignItems="center" height="50vh" justifyContent="center" m={[3]}>
      {props.isActivatingAccount ? (
        <Flex
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <CircularProgress />
          <Text fontSize={[2]} mt={[4]} textAlign="center">
            Activating your account
          </Text>
        </Flex>
      ) : props.isAccountActivated ? (
        <Flex
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <CheckIcon fill={theme.colors.success[0]} height="2em" width="2em" />
          <Text fontFamily="Nunito" fontSize={[2]} mt={[4]} textAlign="center">
            Your behiwot account has been activated. You'll be redirected
            shortly.
          </Text>
        </Flex>
      ) : (
        <Flex>
          <Text
            color="red"
            fontFamily="Nunito"
            fontSize={[2]}
            textAlign="center"
          >
            Could not activate account. The link is invalid or might have
            expired.
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default ActivateAccountPage;
