import { FormValues } from './types';

export const initialValues: FormValues = {
  password: '',
  confirmPassword: '',
};

export const errorMessages = {
  password: {
    min: 'Password is short',
    max: 'Password is long',
    required: 'Password is required',
    invalid: 'Must contain uppercase, lowercase, number and special character',
  },
  confirmPassword: {
    required: 'Re-enter new password',
    invalid: 'Passwords must match',
  },
};
