import * as React from 'react';
import styled from 'styled-components';
import { compose, layout, space, position } from 'styled-system';
import { Flex } from '../Basics';
import { PaperProps } from './types';

const StyledPaper = styled(Flex)`
  position: relative;
  background-color: #fff;
  ${compose(layout, space, position)};
`;
StyledPaper.defaultProps = {
  borderRadius: '8px',
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
};

const Paper = ({ children, ...props }: PaperProps) => {
  return <StyledPaper {...props}>{children}</StyledPaper>;
};

export default Paper;
