import styled from 'styled-components';
import {
  space,
  layout,
  background,
  position,
  compose,
  flexbox,
} from 'styled-system';
import { WrapperProps } from './types';

export const MobileWrapper = styled.div<WrapperProps>`
  ${compose(space, layout, background, position, flexbox)}
  @media (min-width: 601px) {
    display: none;
    box-shadow: 0px 0px 10px black;
  }
`;

export const DesktopWrapper = styled.div<WrapperProps>`
  ${compose(space, layout, background, position, flexbox)}
  @media (max-width: 600px) {
  }
`;
export const OnlyDesktopWrapper = styled.div<WrapperProps>`
  ${compose(space, layout, background, position, flexbox)}
  @media (max-width: 600px) {
    display: none;
  }
`;
