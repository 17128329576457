import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from './index';

const selectSlice = (state: RootState) =>
  state.specialTripListPage || initialState;

//select specialEvents List

// export const selectSpecialTripPage = createSelector(
//   [selectSlice],
//   state => state,
// );

export const selectSpecialTripListISLoading = createSelector(
  [selectSlice],
  state => state.isLoadingEvents,
);
export const selectSpecialEventsInfo = createSelector(
  [selectSlice],
  state => state.specialEventsInfo,
);
