import React from 'react';
import { Pagination as MuiPagination, Stack } from '@mui/material';
import { theme } from 'styles/theme';
import { IPagination } from './types';

const Pagination = (props: IPagination) => {
  return (
    <Stack>
      <MuiPagination
        count={props.count}
        onChange={props.onChange}
        page={props.currentPage}
        shape="rounded"
        sx={{
          '& .MuiPaginationItem-root': {
            color: theme.colors.secondary[0],
            fontSize: props.fontSize || '18px',
            fontWeight: 600,
            minWidth: ['25px', '32px'],
            height: ['20px', '32px'],
            paddingTop: ['3px', 0],
          },
          '& .MuiPaginationItem-root.Mui-selected': {
            backgroundColor: theme.colors.secondary[0],
            color: theme.colors.white[0],
            ':hover': {
              color: theme.colors.secondary[0],
              backgroundColor: 'rgba(0, 0, 0, 0.05)',
            },
          },
        }}
      />
    </Stack>
  );
};

export default Pagination;
