import React from 'react';
import { Skeleton } from '@mui/material';
import { Box, Flex } from '../index';
import { Table, Thead, Tr, Th, Tbody, Td } from '../Table';
import { ITableSkeleton } from './type';
//waiting skeleton
export default function TableSkeleton(props: ITableSkeleton) {
  const { maxWidth, rows, cols } = props;

  const skeletonCols: number[] = [];
  const skeletonRows: number[] = [];

  const addSkeletonRows = () => {
    for (let i = 0; i < rows; i++) {
      skeletonRows.push(i);
    }
    return skeletonRows;
  };
  const addSkeletonCols = () => {
    for (let i = 0; i < cols; i++) {
      skeletonCols.push(i);
    }
    return skeletonCols;
  };
  addSkeletonRows();
  addSkeletonCols();

  return (
    <Box maxWidth={maxWidth} overflowX={'auto'} overflowY="hidden">
      <Table
        height={'100%'}
        mb={['20px', '30px']}
        minWidth={'500px'}
        width={'100%'}
      >
        <Thead>
          <Tr>
            {skeletonCols.map(ele => (
              <Th key={ele}>
                <Skeleton
                  animation="pulse"
                  height="100%"
                  variant="text"
                  width={'100px'}
                />
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {skeletonRows.map(ele => (
            <Tr key={ele}>
              {skeletonCols.map(ele => (
                <Td key={ele}>
                  <Skeleton
                    animation="pulse"
                    height="100%"
                    variant="text"
                    width={'100px'}
                  />
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex
        alignContent={'center'}
        flexDirection={['column', 'row']}
        flexWrap={'wrap'}
        justifyContent={'center'}
        my={['20px', '30px']}
        width={'100%'}
      >
        <Flex style={{ gap: '10px' }}>
          <Skeleton
            animation="pulse"
            height="100%"
            variant="text"
            width={'20px'}
          />
          <Skeleton
            animation="pulse"
            height="100%"
            variant="text"
            width={'20px'}
          />
          <Skeleton
            animation="pulse"
            height="100%"
            variant="text"
            width={'20px'}
          />
        </Flex>
      </Flex>
    </Box>
  );
}
