/* eslint-disable no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { ActivateAccountPageSaga } from './saga';
import { ActivateAccountPageState } from './types';

export const initialState: ActivateAccountPageState = {
  isActivatingAccount: true,
  isAccountActivated: false,
};

const slice = createSlice({
  name: 'activateAccountPage',
  initialState,
  reducers: {
    // eslint-disable-next-line no-unused-vars
    activateAccount: (state, action: PayloadAction<any>) => {
      state.isActivatingAccount = true;
    },
    activateAccountSuccess: (state, action: PayloadAction<any>) => {
      state.isActivatingAccount = false;
      state.isAccountActivated = true;
    },
    activateAccountFailed: state => {
      state.isActivatingAccount = false;
      state.isAccountActivated = false;
    },
  },
});

export const { actions: activateAccountPageActions } = slice;

export const useActivateAccountPageSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: ActivateAccountPageSaga });
  return { actions: slice.actions };
};
