import React from 'react';
import { Box, Button, Flex, Modal, Text } from '../Blocks';
import { Table, Tr, Th, Td, Tbody, Thead } from '../Blocks/Table';
import { SpecialTripListComponentProps } from './types';
import { TableSkeleton } from '../Blocks/Skeleton';
import { Pagination } from '../Blocks/Pagination';
import SpecialTripDetail from './specialTripDetailInfo/SpecialTripDetailInfo';

const SpecialTripListComponent = (props: SpecialTripListComponentProps) => {
  const {
    open,
    count,
    targetPageData,
    handlePageChange,
    isLoading,
    showCreateEvent,
    handleRouteTo,
    handleClose,
    setOpen,
    specialDetailData,
    handleDetailClick,
    confirmType,
    innerModalOpen,
    handleOuterModalClose,
    handleInnerModalOpen,
    handleInnerModalClose,
    confirmDecline,
    handleAfterConfirm,
    confirmApprove,
    handleCancelAction,
    setConfirmType,
  } = props;
  return (
    <>
      <Box ml={['0px', '25px', '70px']}>
        <Flex alignItems={'left'} flexDirection="column" mx={['20px']} my={[0]}>
          <Box>
            <Text
              color={'black.7'}
              fontFamily={`Poppins`}
              fontSize={['16px', '19px']}
              fontWeight={[4, 5]}
              lineHeight="27px"
              mb={[6]}
              mt={[5, 7]}
            >
              Special Trip Request
            </Text>
          </Box>

          <Box my={[5]}>
            {isLoading ? (
              <TableSkeleton
                cols={2}
                maxWidth={['500px', '700px', '70%']}
                rows={4}
              />
            ) : (
              <>
                {targetPageData?.data.length === 0 ||
                targetPageData?.data === undefined ? (
                  showCreateEvent && (
                    <Box>
                      <Text
                        color={'black.7'}
                        fontFamily="Poppins"
                        fontSize={['16px', '18px']}
                        fontWeight={[4]}
                      >
                        You currently have no special trip request
                      </Text>
                      <Button
                        borderRadius={'4px'}
                        my={[5]}
                        onClick={() => {
                          handleRouteTo('/createTrip');
                        }}
                        variant="primary"
                      >
                        <Text
                          fontFamily={'Poppins'}
                          fontSize={['14px', '18px']}
                          fontWeight={[3]}
                          px={[3, 5]}
                          py={[1, 2]}
                        >
                          Create Event
                        </Text>
                      </Button>
                    </Box>
                  )
                ) : (
                  <Box
                    maxWidth={['500px', '700px', '70%']}
                    overflowX={'auto'}
                    overflowY={'hidden'}
                  >
                    <Table height={'100%'} minWidth={'500px'} width={'100%'}>
                      <Thead>
                        <Tr
                          color={'black.5'}
                          fontSize={['13px', '15px', '17px']}
                          fontWeight={[500]}
                          lineHeight={['22px']}
                        >
                          <Th>User</Th>
                          <Th>Category</Th>
                          <Th>Status</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {targetPageData?.data.map(event => (
                          <Tr
                            fontSize={['14px', '14px', '16px']}
                            fontWeight={[500]}
                            key={event._id}
                            lineHeight={['18px']}
                            onClick={() => {
                              setOpen(true);
                              handleDetailClick(event._id);
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <Td style={{ textTransform: 'capitalize' }}>
                              {event.requester.firstName}{' '}
                              {event.requester.lastName}
                            </Td>
                            <Td>{event.category}</Td>
                            <Td>
                              <Text
                                color={
                                  event.isPending
                                    ? 'warning.0'
                                    : event.isAccepted
                                    ? 'success.0'
                                    : 'error.0'
                                }
                              >
                                {event.isPending
                                  ? 'Pending'
                                  : event.isAccepted
                                  ? 'Accepted'
                                  : 'Declined'}
                              </Text>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                    <Flex
                      alignContent={'center'}
                      flexDirection={['column']}
                      flexWrap={'wrap'}
                      my={['20px', '30px']}
                      width={'100%'}
                    >
                      <Box>
                        {count > 1 && (
                          <Pagination
                            count={count}
                            currentPage={targetPageData?.page}
                            fontSize={['13px', '18px']}
                            onChange={handlePageChange}
                          />
                        )}
                      </Box>
                    </Flex>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Flex>
      </Box>
      <Modal borderRadius="5px" onClose={handleClose} opacity={1} open={open}>
        <Box height={[420, 600, '100%']} width={[300, 550, 900]}>
          <SpecialTripDetail
            confirmApprove={confirmApprove}
            confirmDecline={confirmDecline}
            confirmType={confirmType}
            handleAfterConfirm={handleAfterConfirm}
            handleCancelAction={handleCancelAction}
            handleInnerModalClose={handleInnerModalClose}
            handleInnerModalOpen={handleInnerModalOpen}
            handleOuterModalClose={handleOuterModalClose}
            innerModalOpen={innerModalOpen}
            setConfirmType={setConfirmType}
            setOpen={setOpen}
            specialDetailData={specialDetailData}
          />
        </Box>
      </Modal>
    </>
  );
};

export default SpecialTripListComponent;
