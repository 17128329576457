import { Skeleton } from '@mui/material';
import React from 'react';
import { Box, Flex, MobileWrapper, OnlyDesktopWrapper } from '../../Basics';

const CardSkeleton = props => {
  const totalCards = new Array(props.card || 5).fill(undefined);
  return (
    <Box mb={[3, 7]}>
      <OnlyDesktopWrapper>
        <Flex flexWrap="wrap" justifyContent={'center'}>
          {totalCards.map((card, index) => {
            return (
              <Box key={index} mb={[3, 4, 5]} mr={[2]}>
                <Skeleton height={200} variant="rectangular" width={250} />

                <Flex justifyContent={'space-between'} mt={[3, 4]}>
                  <Skeleton height={30} variant="text" width={120} />
                  <Skeleton height={50} variant="text" width={80} />
                </Flex>
                <Skeleton height={25} variant="text" width={100} />
                <Skeleton height={25} variant="text" width={180} />
                <Skeleton height={25} variant="text" width={250} />
                <Flex justifyContent={'space-between'} mt={[2, 3, 4]}>
                  <Skeleton height={50} variant="circular" width={50} />
                  <Skeleton height={40} variant="text" width={80} />
                </Flex>
              </Box>
            );
          })}
        </Flex>
      </OnlyDesktopWrapper>
      <MobileWrapper>
        <Box mr={[2, 3, 4, 5]}>
          <Skeleton height={200} variant="rectangular" width={250} />

          <Flex justifyContent={'space-between'} mt={[3, 4]}>
            <Skeleton height={30} variant="text" width={120} />
            <Skeleton height={50} variant="text" width={80} />
          </Flex>
          <Skeleton height={25} variant="text" width={100} />
          <Skeleton height={25} variant="text" width={180} />
          <Skeleton height={25} variant="text" width={250} />
          <Flex justifyContent={'space-between'} mt={[2, 3, 4]}>
            <Skeleton height={50} variant="circular" width={50} />
            <Skeleton height={40} variant="text" width={80} />
          </Flex>
        </Box>
      </MobileWrapper>
    </Box>
  );
};

export default CardSkeleton;
