// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/fonts/Poppins/Poppins-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("assets/fonts/Poppins/Poppins-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("assets/fonts/Poppins/Poppins-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("assets/fonts/Poppins/Poppins-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("assets/fonts/Poppins/Poppins-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("assets/fonts/Mulish/static/Mulish-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("assets/fonts/Nunito/static/Nunito-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  src: local('Poppins'),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  src: local('Poppins'),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  src: local('Poppins'), url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  src: local('Poppins'),
    url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  src: local('Poppins'),
    url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('truetype');
}

@font-face {
  font-family: 'Mulish';
  font-weight: 400;
  src: local('Mulish'),
    url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-weight: 400;
  src: local('Nunito'),
    url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format('truetype');
}
`, "",{"version":3,"sources":["webpack://./src/styles/fonts.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,gBAAgB;EAChB;8DACkE;AACpE;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;EAChB;8DACoE;AACtE;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,8DAAqE;AACvE;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;EAChB;8DACqE;AACvE;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;EAChB;8DACiE;AACnE;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB;8DACyE;AAC3E;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;EAChB;8DACyE;AAC3E","sourcesContent":["@font-face {\n  font-family: 'Poppins';\n  font-weight: 300;\n  src: local('Poppins'),\n    url('assets/fonts/Poppins/Poppins-Light.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'Poppins';\n  font-weight: 400;\n  src: local('Poppins'),\n    url('assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'Poppins';\n  font-weight: 500;\n  src: local('Poppins'), url('assets/fonts/Poppins/Poppins-Medium.ttf');\n}\n\n@font-face {\n  font-family: 'Poppins';\n  font-weight: 600;\n  src: local('Poppins'),\n    url('assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'Poppins';\n  font-weight: 700;\n  src: local('Poppins'),\n    url('assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'Mulish';\n  font-weight: 400;\n  src: local('Mulish'),\n    url('assets/fonts/Mulish/static/Mulish-Regular.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'Nunito';\n  font-weight: 400;\n  src: local('Nunito'),\n    url('assets/fonts/Nunito/static/Nunito-Regular.ttf') format('truetype');\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
