import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

function valuetext(value: number) {
  return `${value}°C`;
}

export default function RangeSlider(props) {
  return (
    <Box ml={props.ml} mt={props.mt} width={props.width}>
      <Slider
        disableSwap
        getAriaLabel={() => 'Temperature range'}
        getAriaValueText={valuetext}
        max={props.options?.maxPrice}
        min={props.options?.minPrice}
        name="priceSlider"
        onChange={props.handleChange}
        sx={{
          borderRadius: '1px',
          '& .MuiSlider-valueLabelOpen ': {
            backgroundColor: '#fff',
            px: '7px',
            py: '0',
            color: '#FF8058',
            border: '1px solid #FF8058',
            borderRadius: '3px',
            '&:before': {
              borderBottom: '1px solid #FF8058',
              borderRight: '1px solid #FF8058',
            },
          },
          '& .MuiSlider-valueLabel': {
            borderRadius: '7px',
          },
          color: '#FF8058',
        }}
        value={props.value}
        valueLabelDisplay="on"
      />
    </Box>
  );
}
