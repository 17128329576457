/* eslint-disable no-unused-vars */
import { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { defaultLayoutActions as actions } from '.';
import { FormValues } from 'app/pages/LoginPage/types';
import API from 'API';
import { ICurrentUser, ILandingPageSetting } from './types';
import { PayloadAction } from '@reduxjs/toolkit';

function* handleLogin(action: PayloadAction<FormValues>) {
  try {
    const res: AxiosResponse<ICurrentUser> = yield call(API, {
      method: 'POST',
      route: '/user/login',
      payload: action.payload,
    });

    if (res.status === 200) {
      yield put({ type: actions.loginSuccess.type, payload: res.data });
      localStorage.setItem('token', `${res.data.token}`);
      localStorage.setItem('id', `${res.data._id}`);
      localStorage.setItem('email', `${res.data.email}`);
      localStorage.setItem('firstName', `${res.data.firstName}`);
      localStorage.setItem('profileImage', `${res.data.imageId}`);
    }
  } catch (error) {
    yield put({
      type: actions.loginFailed.type,
      payload: error,
    });
  }
}

function* handleLoginWithToken() {
  try {
    const res: AxiosResponse<ICurrentUser> = yield call(API, {
      method: 'GET',
      route: '/user/account',
      token: localStorage.getItem('token'),
    });

    if (res.status === 200) {
      yield put({ type: actions.loginSuccess.type, payload: res.data });
      localStorage.setItem('id', `${res.data._id}`);
      localStorage.setItem('email', `${res.data.email}`);
      localStorage.setItem('firstName', `${res.data.firstName}`);
      localStorage.setItem('profileImage', `${res.data.imageId}`);
    }
  } catch (error) {
    yield put({
      type: actions.loginFailed.type,
      payload: error,
    });
  }
}

function* handleFetchLandingpageSetting() {
  try {
    const res: AxiosResponse<ILandingPageSetting> = yield call(API, {
      method: 'GET',
      route: '/landingpage-setting',
    });
    if (res.status === 200) {
      yield put({
        type: actions.fetchLandingPageSettingSuccess.type,
        payload: res.data,
      });
    }
  } catch (error) {
    yield put({
      type: actions.fetchLandingPageSettingFailed.type,
      payload: error,
    });
  }
}

export function* DefaultLayoutSaga() {
  yield takeLatest(
    actions.fetchLandingPageSettingRequest.type,
    handleFetchLandingpageSetting,
  );
  yield takeLatest(actions.login.type, handleLogin);
  yield takeLatest(actions.loginWithToken.type, handleLoginWithToken);
}
