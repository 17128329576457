import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.resetPasswordPage || initialState;

export const selectResetPasswordPage = createSelector(
  [selectSlice],
  state => state,
);

export const selectIsResettingPassword = createSelector(
  [selectSlice],
  state => state.isResettingPassword,
);

export const selectIsPasswordReset = createSelector(
  [selectSlice],
  state => state.isPasswordReset,
);

export const selectIsValidUrl = createSelector(
  [selectSlice],
  state => state.isValidUrl,
);

export const selectError = createSelector([selectSlice], state => state.error);
