import styled from 'styled-components';

import { RadioProps } from './types';
import { theme } from 'styles/theme';
import {
  compose,
  fontFamily,
  fontSize,
  fontWeight,
  layout,
  space,
} from 'styled-system';
import React from 'react';
import { Field } from 'formik';

export const AttributeContainer = styled.div`
  align-items: center;
  margin: 10px 50px;
`;

export const LabelRadio = styled.label<RadioProps>`
  position: relative;
  padding-left: ${theme.space[4]};
  cursor: pointer;
  font-weight: ${theme.fontWeights[6]};
  font-family: ${theme.fonts[1]};
  font-size: ${theme.fontSizes[1]};
  line-height: ${theme.lineHeights[2]};
  color: ${theme.colors.black[5]};
  ${compose(fontFamily, fontSize, fontWeight, layout, space)};
`;

export const Checkmark = styled.span<RadioProps>`
  position: absolute;
  top: 0;
  right: 90%;
  height: 15px;
  width: 15px;
  border: 1px solid ${theme.colors.secondary[0]};
  border-radius: 50%;
  &::after {
    content: '';
    position: absolute;
    display: none;
    margin: 2px 2px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: ${theme.colors.secondary[0]};
  }
  ${compose(fontFamily, fontSize, fontWeight, layout, space)};
`;

export const InputRadio = styled(Field)`
  display: none;
  &:checked ~ ${Checkmark}:after {
    display: block;
  }
`;

export const RadioBox = (props: RadioProps) => {
  return (
    <AttributeContainer>
      <LabelRadio htmlFor={`${props.value}`}>
        {props.value}
        <InputRadio
          id={props.id}
          name={props.name}
          type="radio"
          value={props.value}
          {...props}
        />
        <Checkmark />
      </LabelRadio>
    </AttributeContainer>
  );
};
