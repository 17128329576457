import CircularProgress from '@mui/material/CircularProgress';
import { ErrorMessage, Form, Formik } from 'formik';
import React from 'react';
import { Button, Flex, Input, Text } from '../Blocks';
import { ForgotPasswordPageProps } from './types';

const ForgotPasswordPage = (props: ForgotPasswordPageProps) => {
  return (
    <Flex alignItems={'center'} justifyContent={'center'} m={3}>
      <Flex
        alignItems={'center'}
        bg={'white.0'}
        borderRadius={2}
        boxShadow={1}
        flexDirection={'column'}
        maxWidth={['370px', '380px']}
        mb={7}
        mt={5}
        pb={7}
        pt={5}
        px={5}
        width={['100%', '65%', '55%']}
      >
        <Text
          as={'h2'}
          color={'black.7'}
          fontSize={1}
          mb={5}
          textAlign={'center'}
        >
          Forgot your account's password? Enter your email address and we'll
          send a recovery link.
        </Text>
        <Formik
          initialValues={props.initialValues}
          onSubmit={props.onSubmit}
          validationSchema={props.validationSchema}
        >
          {({ values }) => (
            <Form style={{ width: '90%' }}>
              <Input
                borderColor={'black.3'}
                borderRadius={2}
                borderWidth={'1.5px'}
                mt={2}
                name="email"
                p={3}
                placeholder="Email"
                type="text"
              />
              <Text variant="error">
                <ErrorMessage name="email" />
              </Text>
              {props.isEmailSent ? (
                <Text
                  color="success"
                  fontSize={0}
                  fontWeight={5}
                  mt={1}
                  textAlign={'center'}
                >
                  Account recovery email has been sent to {values.email}
                </Text>
              ) : (
                props.failureError && (
                  <Text
                    color="red"
                    fontSize={0}
                    fontWeight={6}
                    mt={2}
                    textAlign={'center'}
                  >
                    Something went wrong. Check the email and try again later :(
                  </Text>
                )
              )}
              <Button
                disabled={props.isSendingEmail}
                fontSize={1}
                fontWeight={5}
                mt={1}
                px={3}
                py={3}
                style={{
                  cursor: props.isSendingEmail ? 'not-allowed' : 'pointer',
                }}
                type="submit"
                variant="primary"
                width={'100%'}
              >
                {props.isSendingEmail ? (
                  <CircularProgress size={20} sx={{ color: 'white' }} />
                ) : null}
                &nbsp; Send email
              </Button>
            </Form>
          )}
        </Formik>
      </Flex>
    </Flex>
  );
};

export default ForgotPasswordPage;
