import React from 'react';
import { Flex, Box, Text, Image } from '../Blocks';
import { Link } from 'react-router-dom';
import { ReactComponent as PhoneIcon } from '../../../assets/icons/phone.svg';
import { ReactComponent as MailIcon } from '../../../assets/icons/mail.svg';
import FooterImg from '../../../assets/icons/footer-image.svg';
import FooterStar from '../../../assets/icons/footer-star.svg';
import { FotterProps } from './types';

export const Footer = (props: FotterProps) => {
  return (
    <>
      <Flex
        alignItems={['center', 'inherit', 'inherit']}
        backgroundColor={'white.0'}
        flexDirection={['column', 'row', 'row']}
        gap={['20px', '20px', '40px']}
        justifyContent={'space-between'}
        px={['0', '100px', '400px']}
        py={['10%', '50px', '90px']}
        width="100%"
      >
        <Flex flexDirection={'column'}>
          <Text
            color={'secondary.0'}
            fontSize={[2, 3]}
            fontWeight={[6]}
            textAlign={['center', 'left']}
          >
            <Text color={'black.5'}>Be.</Text>
            hiwot
          </Text>

          <Text
            color={'black.6'}
            fontSize={[1]}
            lineHeight={['22.5px']}
            textAlign={['center', 'left']}
          >
            We provide, <br /> <Text color={'black.5'}>You </Text> choose where
            to go
          </Text>
        </Flex>
        <Flex alignItems={'left'} flexDirection={'column'}>
          <Text
            color={'black.7'}
            fontFamily={'Poppins'}
            fontSize={[1]}
            fontWeight={[5]}
            textAlign={'left'}
          >
            Information
          </Text>
          <Flex
            alignItems={['center', 'flex-start']}
            flexDirection={'column'}
            justifyContent={'space-between'}
            mt={[2]}
          >
            {props.footerSectionOne &&
              props.footerSectionOne.map((footerLinks, index) => {
                return (
                  <Link key={index} to={`/${footerLinks.slug}`}>
                    <Text
                      color={'black.5'}
                      fontFamily={'Poppins'}
                      fontSize={[0]}
                      fontWeight={[3]}
                      lineHeight={[5]}
                    >
                      {footerLinks.title}
                    </Text>
                  </Link>
                );
              })}
          </Flex>
        </Flex>

        <Flex flexDirection={'column'}>
          <Text
            color={'black.7'}
            fontFamily={'Poppins'}
            fontSize={[1]}
            fontWeight={[5]}
            textAlign={['center', 'left']}
          >
            Get in touch
          </Text>
          <Flex mt={[2]}>
            <Flex
              alignItems={['center', 'flex-start']}
              flexDirection={'column'}
              justifyContent="space-between"
              mr={[2, 3]}
            >
              <Box mt={[1]}>
                <PhoneIcon />
              </Box>
              <Box mb={[1]}>
                <MailIcon />
              </Box>
            </Flex>

            <Flex flexDirection={'column'}>
              <a
                data-rel="external"
                href={`tel:${props.footerSectionTwo?.phone1}`}
              >
                <Text
                  color={'black.5'}
                  fontFamily={'Poppins'}
                  fontSize={[0]}
                  fontWeight={[3]}
                  lineHeight={[5]}
                  textAlign={'left'}
                >
                  {props.footerSectionTwo?.phone1}
                </Text>
              </a>
              <a
                data-rel="external"
                href={`tel:${props.footerSectionTwo?.phone2}`}
              >
                <Text
                  color={'black.5'}
                  fontFamily={'Poppins'}
                  fontSize={[0]}
                  fontWeight={[3]}
                  lineHeight={[5]}
                  textAlign={'left'}
                >
                  {props.footerSectionTwo?.phone2}
                </Text>
              </a>
              <a href={`mailto:${props.footerSectionTwo?.email}`}>
                <Text
                  color={'black.5'}
                  fontFamily={'Poppins'}
                  fontSize={[0]}
                  fontWeight={[3]}
                  lineHeight={[5]}
                  textAlign={'left'}
                >
                  {props.footerSectionTwo?.email}
                </Text>
              </a>
            </Flex>
          </Flex>
        </Flex>
        <Box display={['none', 'none', 'block']} position={'relative'}>
          <Image height="233px" src={FooterImg} width="419px" />
          <Image
            position={'absolute'}
            src={FooterStar}
            style={{ zIndex: 10, top: '50%', left: '55%' }}
          />
        </Box>
      </Flex>
      <Flex
        alignItems={'center'}
        backgroundColor={'white.5'}
        height="53px"
        justifyContent={'center'}
        width={'100%'}
      >
        <Text
          color={'black.5'}
          fontFamily={'Poppins'}
          fontSize={[1]}
          fontWeight={[2]}
          lineHeight={[3]}
        >
          Copyright reserved 2022
        </Text>
      </Flex>
    </>
  );
};
