import React from 'react';
import { Box, Flex } from '../../Blocks/Basics';
import { Button, Text } from 'app/components/Blocks';
import { Modal } from 'app/components/Blocks';
import { SpecialTripDetailProp } from '../types';
import { ReactComponent as XIcon } from '../../../../assets/icons/x.svg';

export default function SpecialTripDetailInfo(props: SpecialTripDetailProp) {
  const {
    specialDetailData,
    confirmType,
    innerModalOpen,
    handleInnerModalOpen,
    handleInnerModalClose,
    handleOuterModalClose,
    confirmDecline,
    handleAfterConfirm,
    confirmApprove,
    handleCancelAction,
    setConfirmType,
    setOpen,
  } = props;
  return (
    <>
      <Flex
        alignContent={['start', 'center', 'center']}
        border={'none'}
        flexDirection={['row', 'column', 'column']}
        flexWrap="wrap"
        height="100%"
        justifyContent={'space-between'}
        pb={'10px'}
        style={{
          gap: 10,
        }}
        width={'100%'}
      >
        <Box mx={'auto'} width={['95%', '92%']}>
          <Flex
            alignContent={['center']}
            justifyContent={'space-between'}
            mx={['5px', 'auto']}
            my={['10px']}
          >
            <Box>
              <Text
                color={'secondary.0'}
                fontSize={[2, 2, 3]}
                fontWeight={'bold'}
                my={[1, 2]}
              >
                Special Event
              </Text>
            </Box>
            <Text
              height={['20px', '30px']}
              onClick={handleOuterModalClose}
              style={{
                cursor: 'pointer',
                alignSelf: 'center',
              }}
              width={['25px', '30px']}
            >
              <XIcon
                fill="#485C75"
                height={'100%'}
                stroke="#485C75"
                width={'100%'}
              />
            </Text>
          </Flex>
          <Text
            color={'black.12'}
            fontFamily={`Poppins`}
            fontSize={['17px', '20px']}
            fontWeight={[4, 5]}
            lineHeight={'30px'}
            textAlign={['unset', 'unset']}
          >
            User Data
          </Text>
          <Flex
            flexDirection={['row', 'row']}
            flexWrap={'wrap'}
            justifyContent="space-between"
            mx={['auto']}
            my={[3, 3]}
            width={['90%', '95%']}
          >
            <Flex
              flexDirection={['column', 'column']}
              flexWrap={'wrap'}
              justifyContent="flext-start"
              mx={['auto']}
              width={['50%', '50%']}
            >
              <Box>
                <Text fontSize={['14px', '16px']} fontWeight={[500, 600]}>
                  Requester Name
                </Text>
                <br />
                <Text
                  color={'black.5'}
                  fontSize={['13px', '14px']}
                  style={{ textTransform: 'capitalize' }}
                >
                  {specialDetailData?.requester.firstName}{' '}
                  {specialDetailData?.requester.lastName}
                </Text>
              </Box>
            </Flex>
            <Flex
              flexDirection={['column', 'column']}
              flexWrap={'wrap'}
              justifyContent="flext-start"
              mx={['auto']}
              width={['50%', '50%']}
            >
              <Box>
                <Text fontSize={['14px', '16px']} fontWeight={[500, 600]}>
                  Email
                </Text>
                <br />
                <Text color={'black.5'} fontSize={['13px', '14px']}>
                  {specialDetailData?.requester.email}
                </Text>
              </Box>
            </Flex>
          </Flex>
          <Flex
            flexDirection={['row', 'row']}
            flexWrap={'wrap'}
            justifyContent="space-between"
            mx={['auto']}
            my={[3, 3]}
            width={['90%', '95%']}
          >
            <Box>
              <Text fontSize={['14px', '16px']} fontWeight={[500, 600]}>
                Phone Number
              </Text>
              <br />
              <Text color={'black.5'} fontSize={['13px', '14px']}>
                {specialDetailData?.requester.email}
              </Text>
            </Box>
          </Flex>
          <Text
            color={'black.12'}
            fontFamily={`Poppins`}
            fontSize={['17px', '20px']}
            fontWeight={[4, 5]}
            lineHeight={'30px'}
            textAlign={['unset', 'unset']}
          >
            Location
          </Text>
          <Flex
            flexDirection={['row', 'row']}
            flexWrap={'wrap'}
            justifyContent="space-between"
            mx={['auto']}
            my={[3, 3]}
            width={['90%', '95%']}
          >
            <Flex
              flexDirection={['column', 'column']}
              flexWrap={'wrap'}
              justifyContent="flext-start"
              mx={['auto']}
              width={['50%', '50%']}
            >
              <Box>
                <Text fontSize={['14px', '16px']} fontWeight={[500, 600]}>
                  Departure
                </Text>
                <br />
                <Text
                  color={'black.5'}
                  fontSize={['13px', '14px']}
                  style={{ textTransform: 'capitalize' }}
                >
                  {specialDetailData?.departure}
                </Text>
              </Box>
            </Flex>
            <Flex
              flexDirection={['column', 'column']}
              flexWrap={'wrap'}
              justifyContent="flext-start"
              mx={['auto']}
              width={['50%', '50%']}
            >
              <Box>
                <Text fontSize={['14px', '16px']} fontWeight={[500, 600]}>
                  Destination
                </Text>
                <br />
                <Text
                  color={'black.5'}
                  fontSize={['13px', '14px']}
                  style={{ textTransform: 'capitalize' }}
                >
                  {specialDetailData?.destination}
                </Text>
              </Box>
            </Flex>
          </Flex>
          <Text
            color={'black.12'}
            fontFamily={`Poppins`}
            fontSize={['17px', '20px']}
            fontWeight={[4, 5]}
            lineHeight={'30px'}
            textAlign={['unset', 'unset']}
          >
            Time
          </Text>
          <Flex
            flexDirection={['row', 'row']}
            flexWrap={'wrap'}
            justifyContent="space-between"
            mx={['auto']}
            my={[3, 3]}
            width={['90%', '95%']}
          >
            <Flex
              flexDirection={['column', 'column']}
              flexWrap={'wrap'}
              justifyContent="flext-start"
              mx={['auto']}
              width={['50%', '50%']}
            >
              <Box>
                <Text fontSize={['14px', '16px']} fontWeight={[500, 600]}>
                  Departure Date
                </Text>
                <br />
                <Text color={'black.5'} fontSize={['13px', '14px']}>
                  {new Date(
                    `${specialDetailData?.departureDate}`,
                  ).toLocaleDateString('en-US', {
                    minute: 'numeric',
                    hour: 'numeric',
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  })}
                </Text>
              </Box>
            </Flex>
            <Flex
              flexDirection={['column', 'column']}
              flexWrap={'wrap'}
              justifyContent="flext-start"
              mx={['auto']}
              width={['50%', '50%']}
            >
              <Box>
                <Text fontSize={['14px', '16px']} fontWeight={[500, 600]}>
                  Return Date
                </Text>
                <br />
                <Text color={'black.5'} fontSize={['13px', '14px']}>
                  {new Date(
                    `${specialDetailData?.returnDate}`,
                  ).toLocaleDateString('en-US', {
                    minute: 'numeric',
                    hour: 'numeric',
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  })}
                </Text>
              </Box>
            </Flex>
          </Flex>
          <Text
            color={'black.12'}
            fontFamily={`Poppins`}
            fontSize={['17px', '20px']}
            fontWeight={[4, 5]}
            lineHeight={'30px'}
            textAlign={['unset', 'unset']}
          >
            Amount
          </Text>
          <Flex
            flexDirection={['row', 'row']}
            flexWrap={'wrap'}
            justifyContent="space-between"
            mx={['auto']}
            my={[3, 3]}
            width={['90%', '95%']}
          >
            <Flex
              flexDirection={['column', 'column']}
              flexWrap={'wrap'}
              justifyContent="flext-start"
              mx={['auto']}
              width={['50%', '50%']}
            >
              <Box>
                <Text fontSize={['14px', '16px']} fontWeight={[500, 600]}>
                  Number of people
                </Text>
                <br />
                <Text color={'black.5'} fontSize={['13px', '14px']}>
                  {specialDetailData?.numberOfPeople}
                </Text>
              </Box>
            </Flex>
            <Flex
              flexDirection={['column', 'column']}
              flexWrap={'wrap'}
              justifyContent="flext-start"
              mx={['auto']}
              width={['50%', '50%']}
            >
              <Box>
                <Text fontSize={['14px', '16px']} fontWeight={[500, 600]}>
                  Price per person
                </Text>
                <br />
                <Text color={'black.5'} fontSize={['13px', '14px']}>
                  {specialDetailData?.pricePerOnePerson} ETB
                </Text>
              </Box>
            </Flex>
          </Flex>
          <Text
            color={'black.12'}
            fontFamily={`Poppins`}
            fontSize={['17px', '20px']}
            fontWeight={[4, 5]}
            lineHeight={'30px'}
            textAlign={['unset', 'unset']}
          >
            Category
          </Text>
          <Flex
            flexDirection={['row', 'row']}
            flexWrap={'wrap'}
            justifyContent="space-between"
            mx={['auto']}
            my={[3, 3]}
            width={['90%', '95%']}
          >
            <Text fontSize={['14px', '16px']} fontWeight={[500, 600]}>
              {specialDetailData?.category}
            </Text>
          </Flex>
          <Text
            color={'black.12'}
            fontFamily={`Poppins`}
            fontSize={['17px', '20px']}
            fontWeight={[4, 5]}
            lineHeight={'30px'}
            textAlign={['unset', 'unset']}
          >
            Packages
          </Text>
          <Flex
            flexDirection={['row', 'row']}
            flexWrap={'wrap'}
            gap={[2, 2]}
            mx={['auto']}
            my={[3, 3]}
            width={['90%', '95%']}
          >
            {specialDetailData?.packages.map((item, index) => (
              <Text key={index}>
                {item?.toString()}{' '}
                {index !== specialDetailData?.packages.length - 1 ? ',' : ''}
              </Text>
            ))}
          </Flex>
          <Box mt={[2, 5]} mx={'auto'} width={['90%', '95%']}>
            <Flex
              flexWrap={'wrap'}
              mx={['auto']}
              pb={[3, 1]}
              style={{
                gap: 10,
              }}
              width={['100%']}
            >
              <Button
                backgroundColor={
                  specialDetailData?.isPending ? 'secondary.2' : 'white.0'
                }
                border={
                  specialDetailData?.isPending
                    ? 'none'
                    : specialDetailData?.isAccepted
                    ? '1px solid #19C47C'
                    : '1px solid #F6574C'
                }
                borderRadius={[1]}
                color={
                  specialDetailData?.isPending
                    ? 'white.0'
                    : specialDetailData?.isAccepted
                    ? 'success.0'
                    : 'error.0'
                }
                disabled={!specialDetailData?.isPending}
                fontSize={['12px', '16px']}
                fontWeight={'500'}
                onClick={() => {
                  setConfirmType('Accept');
                  handleInnerModalOpen();
                }}
                px={[2, 3, 4]}
                py={[1, 2]}
                width={['90px', '150px']}
              >
                {specialDetailData?.isPending
                  ? 'Accept'
                  : specialDetailData?.isAccepted
                  ? 'Accepted!'
                  : 'Declined!'}
              </Button>
              <Button
                background="black.0"
                borderRadius={[1]}
                color="white.0"
                fontSize={['12px', '16px']}
                fontWeight={'500'}
                onClick={() => {
                  if (!specialDetailData?.isPending) {
                    setOpen(false);
                  } else {
                    setConfirmType('Decline');
                    handleInnerModalOpen();
                  }
                }}
                px={[2, 3, 4]}
                py={[2, 2]}
                variant="decline"
                width={['90px', '150px']}
              >
                {specialDetailData?.isPending ? 'Decline' : 'Close'}
              </Button>
            </Flex>
          </Box>
        </Box>
        <Modal
          onClose={handleInnerModalClose}
          opacity={1}
          open={innerModalOpen}
        >
          <Box height={[120, 120]} width={[260, 320]}>
            <Flex
              alignContent={['center']}
              alignSelf={'flex-start'}
              flexDirection={['column']}
              flexWrap={'wrap'}
              height={['100%']}
              justifyContent={['space-evenly']}
              width={['100%']}
            >
              <Box mx={'auto'}>
                <Text
                  fontSize={1}
                  fontWeight={[500, 600]}
                  pb={['10px']}
                  textAlign="center"
                >
                  {confirmType} this special event?
                </Text>
              </Box>
              <Box mx={'auto'}>
                <Flex
                  flexWrap={'wrap'}
                  style={{
                    gap: 10,
                  }}
                >
                  <Button
                    backgroundColor="secondary.2"
                    borderRadius={[1]}
                    color="white.0"
                    fontSize={['12px']}
                    onClick={() => {
                      if (confirmType === 'Decline') {
                        confirmDecline(specialDetailData?._id || '');
                        handleAfterConfirm();
                      }
                      if (confirmType === 'Accept') {
                        confirmApprove(specialDetailData?._id || '');
                        handleAfterConfirm();
                      }
                    }}
                    px={[3]}
                    py={[2]}
                    width={['90px']}
                  >
                    Confirm
                  </Button>
                  <Button
                    background="black.0"
                    borderRadius={[1]}
                    color="white.0"
                    fontSize={['12px']}
                    onClick={() => {
                      handleCancelAction();
                    }}
                    px={[3]}
                    py={[1]}
                    variant="cancel"
                    width={['90px']}
                  >
                    Cancel
                  </Button>
                </Flex>
              </Box>
            </Flex>
          </Box>
        </Modal>
      </Flex>
    </>
  );
}
