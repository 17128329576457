import styled from 'styled-components';
import {
  border,
  borderRadius,
  boxShadow,
  color,
  compose,
  fontSize,
  fontWeight,
  layout,
  opacity,
  position,
  shadow,
  space,
  size,
  textAlign,
  variant,
  flexbox,
  background,
} from 'styled-system';
import { ButtonProps } from './types';

export const Button = styled.button<ButtonProps>`
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;

  ${compose(
    space,
    size,
    shadow,
    layout,
    fontSize,
    fontWeight,
    borderRadius,
    color,
    textAlign,
    fontWeight,
    boxShadow,
    position,
    border,
    opacity,
    flexbox,
    background,

    variant({
      variants: {
        primary: {
          color: 'white.0',
          bg: 'primary.3',
          '&:hover': {
            bg: 'primary.2',
          },
        },
        secondary: {
          color: 'white.0',
          bg: 'secondary.2',
          '&:hover': {
            bg: 'secondary.1',
          },
        },
        tertiary: {
          color: 'secondary.2',
          bg: 'white.0',
          '&:hover': {
            bg: 'white.1',
          },
        },
        quaternary: {
          color: 'white.0',
          bg: 'success.0',
          '&:hover': {
            bg: 'success.1',
          },
        },
        quinary: {
          color: 'white.0',
          bg: 'secondary.2',
          '&:hover': {
            bg: 'secondary.1',
          },
        },
        senary: {
          width: '100%',
          fontFamily: 'Poppins',
          fontWeight: 4,
          '&:hover': {
            bg: 'white.1',
          },
        },
        iconPrimary: {
          bg: 'primary.3',
          '&:hover': {
            bg: 'primary.2',
          },
        },
        iconSecondary: {
          bg: 'white.0',
          '&:hover': {
            bg: 'white.1',
          },
        },
        loginSignup: {
          color: 'white.0',
          border: '1px solid #FF8058',
          '&:hover': {
            bg: '#FF8058',
          },
        },
        disabled: {
          color: 'white.0',
          bg: 'gray',
          '&:hover': {
            bg: 'gray',
          },
        },
        cancel: {
          color: 'black.1',
          bg: 'white.0',
          border: '1px solid  #485C75',
          '&:hover': {
            color: 'white.0',
            bg: 'gray',
            border: '1px solid  gray',
          },
        },
        decline: {
          color: 'black.7',
          bg: 'white.9',
          border: '1px solid  #485C75',
        },
      },
    }),
  )};
`;

Button.defaultProps = {
  borderRadius: '8px',
};
