/* eslint-disable no-unused-vars */
import React from 'react';
import { Flex, Text, Box, Button } from '../Blocks';
import heroBackground from '../../../assets/img/hero-background.png';
import { DefaultLayoutParams, HeaderProps } from './types';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ReactComponent as MenuIcon } from '../../../assets/icons/menu.svg';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import Menu from '@mui/material/Menu';

import MenuItem from '@mui/material/MenuItem';
import { Box as MuiBox } from '@mui/material';

export const Header = (props: HeaderProps) => {
  const history = useHistory();
  const location = useLocation();
  const { userId, eventId } = useParams<DefaultLayoutParams>();

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const settings = [
    <Flex
      alignItems={'center'}
      borderBottom="0.1px solid black"
      flexDirection={'column'}
      justifyContent="center"
      pl={2}
      py={2}
      style={{ cursor: 'default' }}
    >
      <Text
        color={'black.1'}
        fontFamily={'Poppins'}
        fontWeight={[3]}
        lineHeight={[2]}
      >
        {localStorage.getItem('firstName')}
      </Text>

      <Text
        color={'black.5'}
        fontFamily={'Poppins'}
        fontSize={['11px']}
        fontWeight={[3]}
        lineHeight={[2]}
        py={1}
      >
        {localStorage.getItem('email')}
      </Text>
    </Flex>,
    <Flex
      onClick={() => {
        history.push('/createTrip');
      }}
      pb={1}
      width={'100%'}
    >
      <Text
        color={'black.1'}
        fontFamily={'Poppins'}
        fontSize={[1]}
        fontWeight={[3]}
        lineHeight={[2]}
      >
        Post an Event
      </Text>
    </Flex>,
    <Flex
      onClick={() => {
        history.push('/userReservationList');
      }}
      pb={1}
      width={'100%'}
    >
      <Text
        color={'black.1'}
        fontFamily={'Poppins'}
        fontSize={[1]}
        fontWeight={[3]}
        lineHeight={[2]}
      >
        Reserved Events
      </Text>
    </Flex>,
    <Flex
      onClick={() => {
        history.push('/search');
      }}
      pb={1}
      width={'100%'}
    >
      <Text
        color={'black.1'}
        fontFamily={'Poppins'}
        fontSize={[1]}
        fontWeight={[3]}
        lineHeight={[2]}
      >
        Feeds
      </Text>
    </Flex>,
    <Flex
      onClick={() => {
        history.push('/savedEvents');
      }}
      pb={1}
      width={'100%'}
    >
      <Text
        color={'black.1'}
        fontFamily={'Poppins'}
        fontSize={[1]}
        fontWeight={[3]}
        lineHeight={[2]}
      >
        Favorites
      </Text>
    </Flex>,
    <Flex
      onClick={() => {
        history.push('/reservationList');
      }}
      pb={1}
      width={'100%'}
    >
      <Text
        color={'black.1'}
        fontFamily={'Poppins'}
        fontSize={[1]}
        fontWeight={[3]}
        lineHeight={[2]}
      >
        Event Request
      </Text>
    </Flex>,
    <Flex
      onClick={() => {
        history.push(`/user/${localStorage.getItem('id')}`);
      }}
      pb={1}
      width={'100%'}
    >
      <Text
        color={'black.1'}
        fontFamily={'Poppins'}
        fontSize={[1]}
        fontWeight={[3]}
        lineHeight={[2]}
      >
        My Profile
      </Text>
    </Flex>,
    <Flex
      onClick={() => {
        history.push(`/editProfile/${localStorage.getItem('id')}`);
      }}
      pb={1}
      width={'100%'}
    >
      <Text
        color={'black.1'}
        fontFamily={'Poppins'}
        fontSize={[1]}
        fontWeight={[3]}
        lineHeight={[2]}
      >
        Edit profile
      </Text>
    </Flex>,
    <Flex onClick={handleClickOpen} width={'100%'}>
      <Text
        color={'black.1'}
        fontFamily={'Poppins'}
        fontSize={[1]}
        fontWeight={[3]}
        lineHeight={[2]}
      >
        Logout
      </Text>
    </Flex>,
  ];

  return (
    <Flex
      alignItems={['center']}
      backgroundImage={`linear-gradient(180deg, #000000 0%, rgba(196, 196, 196, 0) 99.22%), url(${heroBackground})`}
      backgroundPosition={'center'}
      backgroundSize={'cover'}
      flexDirection={['column']}
      pb={[2]}
      pt={[4]}
      px={[5]}
    >
      <Flex
        justifyContent={['space-between']}
        mb={location.pathname === '/' ? 4 : 0}
        width={['100%']}
      >
        <Box
          ml={[0, 3, 4]}
          mt={[1, 2, 3]}
          onClick={() => history.push('/')}
          style={{ cursor: 'pointer' }}
        >
          <Text color={'white.0'} fontSize={[1, 2, 3]} fontWeight={[6]}>
            Be.<Text color={'secondary.0'}>hiwot</Text>
          </Text>
        </Box>
        {location.pathname === '/' ? (
          localStorage.getItem('token') ? (
            <MuiBox sx={{ flexGrow: 0 }}>
              <Flex
                height={['23px', '30px']}
                mr={[0, 3, 4]}
                mt={[1, 2, 3]}
                onClick={handleOpenUserMenu}
                style={{ cursor: 'pointer' }}
                width={['23px', '30px']}
              >
                <MenuIcon height={'100%'} width={'100%'} />
              </Flex>

              <Menu
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                id="menu-appbar"
                keepMounted
                onClose={handleCloseUserMenu}
                open={Boolean(anchorElUser)}
                sx={{
                  mt: '40px',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {settings.map((setting, index) => (
                  <MenuItem key={index} onClick={handleCloseUserMenu}>
                    {setting}
                  </MenuItem>
                ))}
              </Menu>
            </MuiBox>
          ) : (
            <Box
              height={['28px', '34px']}
              mr={[0, 3, 4]}
              mt={[0, 2]}
              width={['82px', '92px']}
            >
              <Button
                fontSize={[0, 1]}
                height={'100%'}
                onClick={
                  props.isLogedin || localStorage.getItem('token')
                    ? handleClickOpen
                    : props.onLoginClick
                }
                pb={['2px', 1]}
                variant={'loginSignup'}
                width={'100%'}
              >
                {props.isLogedin || localStorage.getItem('token')
                  ? 'Sign Out'
                  : 'Sign In'}
              </Button>
            </Box>
          )
        ) : null}
        {location.pathname === '/search' ? (
          localStorage.getItem('token') ? null : (
            <Box
              height={['28px', '34px']}
              mr={[0, 3, 4]}
              mt={[0, 2]}
              width={['82px', '92px']}
            >
              <Button
                fontSize={[0, 1]}
                height={'100%'}
                onClick={
                  props.isLogedin || localStorage.getItem('token')
                    ? handleClickOpen
                    : props.onLoginClick
                }
                pb={['2px', 1]}
                variant={'loginSignup'}
                width={'100%'}
              >
                {props.isLogedin || localStorage.getItem('token')
                  ? 'Sign Out'
                  : 'Sign In'}
              </Button>
            </Box>
          )
        ) : null}
        <Dialog
          aria-describedby="alert-dialog-description"
          aria-labelledby="alert-dialog-title"
          onClose={handleClose}
          open={open}
        >
          <DialogTitle id="alert-dialog-title">
            <Text
              color={'black.7'}
              fontFamily={'Poppins'}
              fontSize={[1, 2]}
              fontWeight={[3]}
              lineHeight={[2]}
            >
              Are you sure you want to Sign out?
            </Text>
          </DialogTitle>

          <DialogActions>
            <Button color="red" fontSize={[1]} onClick={handleClose} p={[2]}>
              Cancel
            </Button>
            <Button
              autoFocus
              color="primary.0"
              fontSize={[1]}
              onClick={props.onLogoutClick}
              p={[2]}
            >
              Sign out
            </Button>
          </DialogActions>
        </Dialog>
      </Flex>
      <Box
        mt={[4, 0]}
        position={location.pathname === '/' ? null : ['static', 'absolute']}
        top={['0px', '18px', '15px']}
      >
        <Text
          color={'secondary.0'}
          fontSize={location.pathname === '/' ? [2, 4, 6] : [2, 3, 4]}
          fontWeight={[6]}
        >
          Discover <Text color={'white.0'}>what's out</Text>
        </Text>
      </Box>
      {location.pathname === '/' ? (
        <Box mb={location.pathname === '/' ? [7, 8] : [3, 4]}>
          <Text color={'white.0'} fontSize={[0, 2]} fontWeight={6}>
            Hiking. Trips. Events
          </Text>
        </Box>
      ) : null}
    </Flex>
  );
};
