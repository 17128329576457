import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) =>
  state.forgotPasswordPage || initialState;

export const selectForgotPasswordPage = createSelector(
  [selectSlice],
  state => state,
);

export const selectIsEmailSent = createSelector(
  [selectSlice],
  state => state.isEmailSent,
);

export const selectIsSendingEmail = createSelector(
  [selectSlice],
  state => state.isSendingEmail,
);

export const selectError = createSelector([selectSlice], state => state.error);
