import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ComboBoxProps } from './types';
import { ReactComponent as ChevronUp } from 'assets/icons/chevron-down.svg';

export default function ComboBox(props: ComboBoxProps) {
  return (
    <Autocomplete
      disablePortal
      id="selectedDestination"
      onChange={props.handleChange}
      options={props.options as string[]}
      popupIcon={<ChevronUp />}
      renderInput={params => (
        <TextField
          sx={{ label: {} }}
          {...params}
          placeholder={props.placeholder}
        />
      )}
      sx={{
        label: {
          color: '#9DA7BC',
          fontWeight: 700,
          lineHeight: '14px',
          fontSize: '12px',
          paddingLeft: '8px',
          paddingTop: '12px',
          pb: '3px',
        },
        svg: {
          color: '#FF8058',
        },
        '& .MuiOutlinedInput-root': {
          borderRadius: '8px',
          height: props.height || '45px',
          backgroundColor: '#F5F5F7',
          py: '0',
          pl: '15px',
          '&::before': {
            content: '""',
            marginRight: '4px',
            borderLeft: '1px solid #FF8058',
            height: '20px',
          },
        },
        '& .MuiButtonBase-root': {
          height: '25.56px',
          width: '25.56px',
        },
      }}
      value={props.value}
    />
  );
}
