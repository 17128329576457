import { Skeleton } from './index';
import React from 'react';
import { IImageSkeleton } from './type';
import { Box } from '../Basics';

export default function ImageSkeleton(props: IImageSkeleton) {
  const { width, height, animation, variant, borderRadius } = props;
  return (
    <Box borderRadius={borderRadius} height={height} width={width}>
      <Skeleton
        animation={animation}
        height={'100%'}
        variant={variant}
        width={'100%'}
      />
    </Box>
  );
}

ImageSkeleton.defaultProps = {
  with: ['400px', '500px', '800px'],
  height: ['200px', '300px', '400px'],
  variant: 'rectangular',
  animation: 'wave',
  broderRadius: '10px',
};
