import { Box, Skeleton } from '@mui/material';
import React from 'react';
import { Flex } from '../../Basics';

const TopDestinationSkeleton = () => {
  return (
    <Flex
      alignItems={'center'}
      flexDirection={['column', 'column', 'row']}
      justifyContent={'center'}
      width={'100%'}
    >
      <Flex mr={[0, 2, 3, 4]} width={['100%', '100%', '50%']}>
        <Flex flexDirection={'column'} mr={[1, 2, 3, 4]} width="50%">
          <Box
            height={['120px', '168px', '182px', '238px']}
            mb={[1, 2, 3, 4]}
            width={'100%'}
          >
            <Skeleton height={'100%'} variant="rectangular" width={'100%'} />
          </Box>
          <Box
            height={['120px', '168px', '182px', '238px']}
            mb={[1, 2, 3, 4]}
            width={'100%'}
          >
            <Skeleton height={'100%'} variant="rectangular" width={'100%'} />
          </Box>
        </Flex>
        <Flex width="50%">
          <Box height={['248px', '352px', '388px', '508px']} width={'100%'}>
            <Skeleton height={'100%'} variant="rectangular" width={'100%'} />
          </Box>
        </Flex>
      </Flex>
      <Flex flexDirection={'column'} width={['100%', '100%', '50%']}>
        <Box
          height={['120px', '168px', '182px', '238px']}
          mb={[1, 2, 3, 4]}
          width={'100%'}
        >
          <Skeleton height={'100%'} variant="rectangular" width={'100%'} />
        </Box>
        <Flex>
          <Box
            height={['120px', '168px', '182px', '238px']}
            mb={[1, 2, 3, 4]}
            mr={[1, 2, 3, 4]}
            width={'100%'}
          >
            <Skeleton height={'100%'} variant="rectangular" width={'100%'} />
          </Box>
          <Box height={['120px', '168px', '182px', '238px']} width={'100%'}>
            <Skeleton height={'100%'} variant="rectangular" width={'100%'} />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TopDestinationSkeleton;
