import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import API from 'API';
import { resetPasswordPageActions as actions } from '.';
import { IPayload } from './types';

function* handleResetPassword(action: PayloadAction<IPayload>) {
  try {
    yield call(API, {
      method: 'POST',
      route: '/user/reset-password',
      payload: action.payload,
    });

    yield put({ type: actions.resetPasswordSuccess.type });
  } catch (error) {
    yield put({ type: actions.resetPasswordFailed.type, payload: error });
  }
}

export function* ResetPasswordPageSaga() {
  yield takeLatest(actions.resetPassword.type, handleResetPassword);
}
