import axios from 'axios';

interface ApiTypes {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  route: string;
  payload?: any;
  token?: any;
  params?: any;
}
interface WeatherApiTypes {
  lat: number;
  long: number;
}
axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}api`;

function API({ method, route, payload, params, token }: ApiTypes): any {
  return new Promise((resolve, reject) => {
    axios({
      method: method,
      url: route,
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: params,
      timeout: 17000,
    })
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        if (error?.response?.status === 500) {
          console.log(error?.response?.data?.message || 'Something went wrong');
        } else {
          reject(error?.response?.data?.message || 'Something went wrong');
        }
      });
  });
}

export default API;

export function fetcher({ method, route, payload, token }: ApiTypes): any {
  return new Promise((resolve, reject) => {
    fetch(
      `${
        process.env.REACT_APP_BASE_URL || window.location.origin
      }/api/${route}`,
      {
        method: method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: payload,
      },
    ).then(res => {
      if (res.status === 200) {
        resolve(res.json());
      } else if (res.status === 500) {
        console.log(res.statusText || 'Something went wrong');
      } else {
        reject(res.statusText || 'Something went wrong');
      }
    });
  });
}

export function Weather({ lat, long }: WeatherApiTypes): any {
  return new Promise((resolve, reject) => {
    fetch(
      `${process.env.REACT_APP_API_URL}/weather/?lat=${lat}&lon=${long}&units=metric&APPID=${process.env.REACT_APP_API_KEY}`,
    ).then(res => {
      if (res.status === 200) {
        resolve(res.json());
      } else {
        reject();
      }
    });
  });
}
