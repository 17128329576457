import { Box } from '../Basics/Box';
import styled from 'styled-components';
import {
  compose,
  space,
  borderRadius,
  background,
  border,
  position,
  shadow,
  typography,
  grid,
  layout,
} from 'styled-system';
import { ImageProps } from './types';

export const Image = styled.img<ImageProps>`
  object-fit: cover;
  display: flex;
  ${compose(
    space,
    typography,
    background,
    border,
    position,
    shadow,
    borderRadius,
    grid,
    layout,
  )}
`;
interface GradProps {
  borderRadius?: string | string[];
}

export const Grad = styled(Box)<GradProps>`
  position: relative;
  &:after {
    content: '';
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.23) 0%,
      rgba(0, 0, 0, 0.42) 100%
    );
    border-radius: ${props => props.borderRadius};
  }
`;
