import styled from 'styled-components';
import {
  space,
  color,
  layout,
  grid,
  background,
  border,
  borderRadius,
  position,
  shadow,
  compose,
} from 'styled-system';
import { BoxProps } from './types';

export const Box = styled.div<BoxProps>`
  &::-webkit-scrollbar {
    width: 5px;
    scroll-behavior: smooth;
  }
  &::-webkit-scrollbar:horizontal {
    height: 5px;
    scroll-behavior: smooth;
  }
  &::-webkit-scrollbar-track {
    background-color: transparentize(#ccc, 0.7);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: transparentize(#ccc, 0.5);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  ${compose(
    space,
    color,
    layout,
    grid,
    background,
    border,
    borderRadius,
    position,
    shadow,
  )}
`;
