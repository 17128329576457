/* eslint-disable no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { DefaultLayoutState, ICurrentUser, ILandingPageSetting } from './types';
import { FormValues } from 'app/pages/LoginPage/types';
import { DefaultLayoutSaga } from './saga';

export const initialState: DefaultLayoutState = {
  isLogging: false,
  isLoggedIn: false,
  isLoadingLandingPageSetting: false,
  currentUser: undefined,
  errorMessage: '',
  landingPageSetting: undefined,
};
const slice = createSlice({
  name: 'defaultLayout',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<FormValues>) => {
      state.isLogging = true;
    },
    loginSuccess: (state, action: PayloadAction<ICurrentUser>) => {
      state.isLogging = false;
      state.isLoggedIn = true;
      state.currentUser = action.payload;
    },
    loginFailed: (state, action: PayloadAction<string>) => {
      state.isLogging = false;
      state.errorMessage = action.payload;
    },
    logout: state => {
      state.isLoggedIn = false;
      state.currentUser = undefined;
      localStorage.removeItem('token');
      localStorage.removeItem('id');
      localStorage.removeItem('email');
      localStorage.removeItem('firstName');
      localStorage.removeItem('profileImage');
    },
    loginWithToken: state => {
      state.isLogging = true;
    },
    fetchLandingPageSettingRequest: state => {
      state.isLoadingLandingPageSetting = true;
    },
    fetchLandingPageSettingSuccess: (
      state,
      action: PayloadAction<ILandingPageSetting>,
    ) => {
      state.isLoadingLandingPageSetting = false;
      state.landingPageSetting = action.payload;
    },
    fetchLandingPageSettingFailed: (
      state,
      action: PayloadAction<ILandingPageSetting>,
    ) => {
      state.isLoadingLandingPageSetting = false;
      state.landingPageSetting = action.payload;
    },
  },
});

export const { actions: defaultLayoutActions } = slice;

export const useDefaultLayoutSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: DefaultLayoutSaga });
  return { actions: slice.actions };
};
