import React from 'react';
import { Helmet } from 'react-helmet-async';
import ResetPasswordComponent from 'app/components/ResetPasswordPage';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useResetPasswordPageSlice } from './slice';
import {
  selectIsResettingPassword,
  selectIsPasswordReset,
  selectError,
  selectIsValidUrl,
} from './slice/selector';
import { initialValues } from './constants';
import { validationSchema } from './validators';
import { FormValues } from './types';

export function ResetPasswordPage() {
  const params = new URLSearchParams(window.location.search);
  const history = useHistory();
  const dispatch = useDispatch();
  const { actions } = useResetPasswordPageSlice();
  const isResettingPassword = useSelector(selectIsResettingPassword);
  const isPasswordReset = useSelector(selectIsPasswordReset);
  const isValidUrl = useSelector(selectIsValidUrl);
  const error = useSelector(selectError);
  const [showPassword, setShowPassword] = React.useState(false);

  React.useEffect(() => {
    dispatch(actions.setIsValidUrl(Boolean(params.get('token'))));
  }, []);

  React.useEffect(() => {
    if (isPasswordReset) setTimeout(() => history.push('/login'), 2000);
  }, [history, isPasswordReset]);

  function handleShowPassword() {
    setShowPassword(!showPassword);
  }

  function onSubmit(values: FormValues) {
    dispatch(
      actions.resetPassword({
        token: params.get('token'),
        password: values.password,
      }),
    );
  }

  return (
    <>
      <Helmet title="Recover account" />
      <ResetPasswordComponent
        error={error}
        handleShowPassword={handleShowPassword}
        initialValues={initialValues}
        isPasswordReset={isPasswordReset}
        isResettingPassword={isResettingPassword}
        isValidUrl={isValidUrl}
        onSubmit={onSubmit}
        showPassword={showPassword}
        validationSchema={validationSchema}
      />
    </>
  );
}
