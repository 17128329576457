import {
  background,
  border,
  color,
  compose,
  layout,
  space,
  typography,
} from 'styled-system';
import styled from 'styled-components';
import { TableProps } from './types';

export const Table = styled.table<TableProps>`
  text-align: center;
  ${compose(background, border, color, layout, space, typography)};
`;
export const Tbody = styled.tbody<TableProps>``;

export const Thead = styled.thead<TableProps>`
  border-top-left-radius: '10px';
  border-top-right-radius: '10px';
`;

export const Tr = styled.tr<TableProps>`
  ${compose(background, border, color, layout, space, typography)};
`;
export const Th = styled.th<TableProps>`
  background-color: #f6f6f9;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: '5px 0px 0px 0px';
  padding: 20px 50px;
  ${compose(background, border, color, layout, space, typography)};
`;
export const Td = styled.td<TableProps>`
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  color: #656a71;
  padding: 20px 50px;
  ${compose(background, border, color, layout, space, typography)}
`;
